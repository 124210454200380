<template>
  <div id="app" :class="this.$store.state.fullScreenOrNot ? scrollClass1 : scrollClass">
    <router-view></router-view>
    <div class="bj_style">
      <div class="bj_style_2">
        <div class="bj_style_3" :style="svgBg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import svgBg from '@/assets/bg-diamante.svg'
import hahamini from '@/assets/hahamini.svg'
import zzboom from '@/assets/zzboom.svg'
import agame from '@/assets/agame.svg'
import minijoy from '@/assets/minijoy.svg'
import kkplay from '@/assets/kkplay.svg'
import playus from '@/assets/playus.svg'
import oppogame from '@/assets/oppogame.svg'
import joymarvel from '@/assets/joymarvel.svg'
import gamesinall from '@/assets/gamesinall.svg'
import littlefun from '@/assets/littlefun.svg'
import minifun from '@/assets/minifun.svg'
import plusfun from '@/assets/plusfun.svg'
import getfun from '@/assets/getfun.svg'
import asshead from '@/assets/asshead.svg'
import slgplay from '@/assets/slgplay.svg'
import ftgplay from '@/assets/ftgplay.svg'
import pzlplay from '@/assets/pzlplay.svg'
import avgplay from '@/assets/avgplay.svg'
import stgplay from '@/assets/stgplay.svg'
import mobaplay from '@/assets/mobaplay.svg'
import etcplay from '@/assets/etcplay.svg'
import letmeme from '@/assets/letmeme.svg'
import kknock from '@/assets/kknock.svg'
import yamat from '@/assets/yamat.svg'
import timiplay from '@/assets/timiplay.svg'
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      svgBg: {
        backgroundImage: `url(${this.$originCopyWriting == 'hahamini' ? hahamini :
            this.$originCopyWriting == 'zzboom' ? zzboom :
                this.$originCopyWriting == 'agame' ? agame :
                    this.$originCopyWriting == 'minijoy' ? minijoy :
                    this.$originCopyWriting == 'kkplay' ? kkplay :
                    this.$originCopyWriting == 'playus' ? playus :
                    this.$originCopyWriting == 'oppogame' ? oppogame :
                    this.$originCopyWriting == 'joymarvel' ? joymarvel :
                    this.$originCopyWriting == 'gamesinall' ? gamesinall :
                    this.$originCopyWriting == 'littlefun' ? littlefun :
                    this.$originCopyWriting == 'minifun' ? minifun :
                    this.$originCopyWriting == 'plusfun' ? plusfun :
                    this.$originCopyWriting == 'getfun' ? getfun :
                    this.$originCopyWriting == 'asshead' ? asshead :
                    this.$originCopyWriting == 'slgplay' ? slgplay :
                    this.$originCopyWriting == 'ftgplay' ? ftgplay :
                    this.$originCopyWriting == 'pzlplay' ? pzlplay :
                    this.$originCopyWriting == 'avgplay' ? avgplay :
                    this.$originCopyWriting == 'stgplay' ? stgplay :
                    this.$originCopyWriting == 'mobaplay' ? mobaplay :
                    this.$originCopyWriting == 'etcplay' ? etcplay :
                    this.$originCopyWriting == 'letmeme' ? letmeme :
                    this.$originCopyWriting == 'kknock' ? kknock :
                    this.$originCopyWriting == 'yamat' ? yamat :
                    this.$originCopyWriting == 'timiplay' ? timiplay :
                        svgBg})`
      },
      scrollClass: 'scrollClass', // 滚动条样式
      scrollClass1: 'scrollClass1', // 滚动条样式
    }
  },
  mounted() {

  }
}
</script>
<style lang="less">
.scrollClass{
  overflow: visible;
  height: 100%;
}
.scrollClass1{
  overflow: hidden;
  height: 100vh;
}
.el-loading-spinner{
  font-size: 50px;
}
@media (hover: hover) and (pointer: fine){
  .bj_style_3 {
    background-size: 40%;
  }
}
.bj_style {
  position: fixed;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 100vh;
  contain: strict;
}
.bj_style_2{
  position: absolute;
  inset: -50vh 0px 0px;
  overflow: hidden;
  z-index: -2;
  //transform: translateY(50vh);
  .bj_style_3{
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 100%;
  }
}
</style>
<style>

</style>
