<template>
  <div id="mobile_classify" v-title :data-title="`${$headToUpperCase} Game Types ——${gameType}`">
    <div class="content">
      <MobileLogo whereFrom="3" portal="mobile_tab"></MobileLogo>
      <div v-if="clientWidth < 550.9">
        <div class="big_box" :class="index == 0 ? eZzVdABef : null" v-for="(item,index) in gameList" :key="index">
          <div :class="index == 0
           ? 'game_box_1' : 'game_box'">
            <div class="game-name" v-if="index == 0" style="grid-area: ibx">
              <h1>{{ gameType }}</h1>
            </div>
            <div class="adv" v-if="index == 0" style="grid-area: adv;display: flex;justify-content: center;align-items: center" @mouseenter="advMouseenter">
                <div v-if="mNoAfc" :id="gptId" ref="advRef"></div>
              <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
                   style="display:block;width: 100%"
                   :data-ad-client="caPub"
                   :data-ad-slot="slot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <a
                v-for="(items,indexs) in item"
                :key="indexs" :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+items.Name.replace(/\s+/g, '')+'?gameId='+items.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+items.Name.replace(/\s+/g, '')+'?gameId='+items.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"
                class="sc-wr3rvk-0 a_style_1 a_style_2"
                :style="(indexs == 0 && item.length >4) || (indexs == 6 && item.length > 9) ? {gridArea: 'bigIp' + indexs}: null"
                @click="iconClick(items)">
              <img v-if="(indexs == 0 && item.length >4) || (indexs == 6 && item.length > 9)" v-lazy="items.iconUrl" alt="" width="204px" height="204px" class="img_style">
              <img v-else v-lazy="items.iconUrl" alt="" width="94px" height="94px" class="img_style">
              <span class="opacity position">{{items.Name}}</span>
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="big_box" :class="eZzVdABef">
          <div :class="'game_box_1'">
            <div class="game-name" style="grid-area: ibx">
              <h1>{{ gameType }}</h1>
            </div>
            <div class="adv" style="grid-area: adv;display: flex;justify-content: center;align-items: center" @mouseenter="advMouseenter">
                <div v-if="mNoAfc" :id="gptId" ref="advRef"></div>
              <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
                   style="display:block;width: 100%"
                   :data-ad-client="caPub"
                   :data-ad-slot="slot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <a v-for="(item,index) in gameList" :key="index" :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" class="sc-wr3rvk-0 a_style_1 a_style_2" :style="(index == 0 && gameList.length > 5) || (index == 1 && gameList.length > 5) ? {gridArea: 'bigIp' + index}: null" @click="iconClick(item)">
              <img v-if="(index == 0 && gameList.length > 5) || (index == 1 && gameList.length > 5)" v-lazy="item.iconUrl" alt="" width="204px" height="204px" class="img_style">
              <img v-else v-lazy="item.iconUrl" alt="" width="94px" height="94px" class="img_style">
              <span class="opacity position">{{item.Name}}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="type_box" v-if="!gameList.length">
        <div class="type_style">
          <div class="game-name" style="grid-area: ibx">
            <h1>{{ gameType }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <ClassList :gameTypeList="recommend" :styleType="true" fromWhere="1"></ClassList>
      <BottomList whereFrom="3"></BottomList>
      <div class="bottom-text">
<!--        <nav class="about">-->
<!--          <ul>-->
<!--            <li><a href="/">Games</a></li>-->
<!--            <li>{{gameType}} games</li>-->
<!--          </ul>-->
<!--        </nav>-->
        <div class="title"><h2>{{gameType.slice(0,1).toUpperCase() + gameType.slice(1).toLowerCase()}} Games</h2></div>
        <div v-if="gameType == 'SHOOTING'">
          <p>Expensive video game consoles are indeed not the only option for playing shooting games. Nowadays, there's a wide variety of free-to-play shooting games available online, accessible right on your computer. These games offer a diverse range of experiences, allowing you to play as marksmen in various environments, sneaking through levels, and firing at enemies.</p>
          <p>You can customize your character and create classes with a wide array of guns, from pistols to assault rifles. Whether you want to play as a futuristic assassin with ultra-modern weapons or relive the classic Doom game series, there's something for everyone.</p>
          <p>Moreover, these shooting games come with various game modes, such as single-player missions, multiplayer deathmatches, and cooperative tasks. You can choose to play solo, holstering your weapon and facing the challenges alone, or team up with other players to unleash ammo as a member of a multiplayer task force.</p>
          <p>The best part is that you can play these online shooting games for free on your PC, without even having to download them. Most of these games are also compatible with mobile phones and tablets, allowing you to enjoy the shooting experience anytime, anywhere.</p>
          <p>So, why wait? Head over to {{$originCopyWriting}} and explore our collection of the best free online shooting games. Play now and immerse yourself in the world of marksmanship, where the possibilities are endless!</p>
          <!--          <ul>-->
          <!--            <li>WASD - Move Around</li>-->
          <!--            <li>Mouse - Aim</li>-->
          <!--            <li>Left Mouse Click - Shoot</li>-->
          <!--            <li>Right Mouse Click - Zoom In</li>-->
          <!--            <li>Spacebar - Jump</li>-->
          <!--            <li>Shift - Sprint</li>-->
          <!--            <li>R - Reload</li>-->
          <!--          </ul>-->
        </div>
        <div v-if="gameType == 'CAR'">
          <p>If you're passionate about cars, whether it's race cars, dune buggies, or motorcycles, we've got the perfect car games for you. These games offer a thrilling experience that will keep you engaged for hours.</p>
          <p>In our racing challenges, you can experience the rush of accelerating from 0 to 60 mph in mere seconds. Challenge yourself to set new speed records as you race against the clock or compete against other players.</p>
          <p>For those who love off-road adventures, we have a range of games where you can traverse over mountains, junkyards, and other exotic terrains. Drive your off-road vehicle through challenging obstacles and enjoy the freedom of exploring different environments.</p>
          <p>And if you're a fan of customization, our car games allow you to create your very own vehicle. Choose from hundreds of options, including different body styles, colors, and upgrades, to create a unique ride that reflects your personal style.</p>
          <p>Not only that, but some of our games are even available in 3D, providing an even more immersive experience. The controls are easy to use, making the games fun for players of all ages and skill levels.</p>
          <p>You can play all our car games online on your PC, using your keyboard to accelerate, brake, and steer. Some games even allow you to modify the environment and balance your vehicle with a simple press of the arrow keys. And if you're on the go, don't worry! Many of our car games are also compatible with mobile phones and tablets, allowing you to play and enjoy the car games on the go.</p>
          <p>So, whether you're a speed demon who loves to race or an adventurer who enjoys off-road challenges, we have the perfect car game for you. Start your engine, hit the gas, and immerse yourself in the world of car games today!</p>
        </div>
        <div v-if="gameType == 'BALL'">
          <p>Experience the thrill of competing against the world's best athletes and winning championship tournaments with our ball games! Whether you're a football fanatic, a basketball legend, or a baseball superstar, we have the perfect game for you.</p>
          <p>Step onto the gridiron and feel like a real NFL quarterback as you throw touchdown passes and lead your team to victory. Or, take on the role of a soccer all-star and show your skills with free kicks and amazing saves by your goalkeeper.</p>
          <p>Our basketball challenges will make you feel like Michael Jordan as you score against the best NBA teams. Dunk, shoot, and rebound your way to the championship! And, if you're a baseball fan, our baseball challenges will let you hit home runs and win backyard games against your friends.</p>
          <p>With our ball games, you'll learn how to kick, pass, toss, and shoot like Hall of Fame athletes. Compete in grueling tournaments, win the playoffs, and take home the gold medal!</p>
          <p>So, why wait? Start playing our ball games now and experience the excitement of becoming a real sports superstar! Show your skills, win championships, and make a name for yourself in the world of sports!</p>
        </div>
        <div v-if="gameType == 'GIRLS'">
          <p>Embark on a journey of fun and adventure with our extensive collection of girl games! Whether you're looking to show off your extreme gaming skills or simply relax with fashion-based games, we have something for every girl.</p>
          <p>Dress up cute characters and admirable women, create outfits for celebrities, or design dresses for dolls. Show your creativity and style sense as you customize every detail from hair to shoes.</p>
          <p>For a romantic adventure, choose from a variety of cute date games where you can fall in love and build meaningful relationships. Or, if you're feeling more playful, try taking care of your own virtual pet and building a strong bond with them.</p>
          <p>Our collection also features licensed dolls and characters like Barbie and Bratz, allowing you to play with replicas of your favorite toys. Bring your toy chest to life with highly authentic, virtual characters that bring the fun and excitement of the real world into the digital realm.</p>
          <p>With games featuring Sue, Winx, Nick Jr.'s Dora the Explorer, and more, our playful collection is sure to make every girl happy. So, why wait? Jump into the world of girl games and immerse yourself in a fun and exciting adventure that's tailored just for you!</p>
        </div>
        <div v-if="gameType == 'CASUAL'">
          <p>Casual games are indeed the perfect blend of relaxation and entertainment, offering a refreshing break from the daily grind. With their easy-to-learn yet challenging enough nature, they allow you to unwind and de-stress while still offering a satisfying gaming experience.</p>
          <p>At our casual game collection, diversity and creativity are at the forefront. We offer a range of engaging elimination games that are not only fun but also help to relieve stress. If you prefer a more relaxed pace, our bubble shooters will provide hours of enjoyable play. For those who love a challenge, our agility games will test your reflexes and response times.</p>
          <p>And, of course, we wouldn't forget the fun and creativity aspect! Graffiti, dress-up, and puzzle games are all here, ready to spark your imagination and keep you engaged for hours. Whether you're looking for a quick break or a longer gaming session, our casual games will ensure you never get bored.</p>
          <p>So, why wait? Dive into our collection of casual games and let the fun and relaxation begin!</p>
        </div>
        <div v-if="gameType == 'PUZZLE'">
          <p>Immersive graphics and seamless gameplay are integral to our extensive range of puzzle games. Each title is designed to push your strategic acumen and problem-solving skills to the limit, offering thousands of unique challenges to keep you engaged.</p>
          <p>In our diverse challenges, you'll build bridges, align shifting shapes, and chart routes to victory. Test your spatial awareness with 3D Rubik's Cubes, compete against AI opponents in Tic-Tac-Toe, and trigger captivating chain reactions across our extensive library.</p>
          <p>Get your brain in gear with our exciting puzzles. Whether you prefer Orbox, rotation games, or something entirely different, we've got something for every type of player. Explore arcade-style 2D graphics or neon-infused 3D artistry as you embark on puzzle-solving adventures.</p>
          <p>Challenge your wits with Rube Goldberg contraptions, engineering mysteries, and other mind-bending puzzles. With our diverse collection, boredom will never set in! All you need is a mouse and keyboard to unlock the endless fun and excitement of our puzzle games. So, why wait? Jump into the world of puzzles and let your imagination run wild!</p>
        </div>
        <div v-if="gameType == 'ACTION'">
          <p>Action Games are the ultimate adrenaline rush, packing satisfying guns, explosive gameplay, and a randomized weapon system into every title. Whether you're duking it out in Mini Royale, Repuls.io, or any other top-rated action game, the non-stop thrill and excitement are guaranteed.</p>
          <p>Designed for mobile play, these games feature hand-to-hand combat at its finest, blending hack-and-slash, war, and action themes into a pulse-pounding experience. Whether you're in the mood for an adrenaline-fueled rush or a casual arcade session, you'll find a game that suits your style.</p>
          <p>With hundreds of action games available, we've handpicked the best for mobile players. Multiplayer action games let you square off against other players from around the world, while games with huge maps offer unlimited exploration potential. Puzzle-based action games挑战 your mind as well as your reflexes, and those with epic graphics and cool props will keep you entertained for hours.</p>
          <p>From 3D blockbusters to 2D side-scrollers, Action Games are perfect for anyone who loves to act cool under pressure. Engage in fast-paced battles, using melee and ranged attacks to take down opponents. Collect items on the battlefield, upgrade your weapons, and aim for headshots and critical hits to secure victory. So, grab your mobile device and dive into the world of Action Games – the ultimate saga awaits!</p>
        </div>
        <div v-if="gameType == 'RACING'">
          <p>Dive into an exciting world of racing games, where the thrill of the chase and the rush of victory await you! Choose from a wide array of vehicles, from sports cars to boats, and race across diverse tracks in various environments. Compete with friends or challenge other racers online for the ultimate rush.</p>
          <p>Master the controls and navigate the racetracks with precision, using responsive controls and realistic cockpit graphics. Skid around turns, speed through straightaways, and use your mirrors to get the perfect angle. Catch a tailwind, drift behind your opponents, and make your way to the front of the pack.</p>
          <p>Race on land, water, or even through the sky! Conquer dirt courses, bustling city streets, and open waterways. Challenge yourself with different difficulty levels and prove your mastery of all modes of transportation.</p>
          <p>With our collection of racing games, the fun never stops. So, gear up, rev up your engine, and race to the finish line – the gold medal is waiting for you!</p>
        </div>
        <div v-if="gameType == 'PARKOUR'">
          <p>Embark on an adrenaline-pumping journey through our parkour games, where you'll run, flip, and wall-jump through amazing urban courses! Based on military training obstacle courses, parkour is an art that demands total body control and precision. Face diverse barriers in each course and figure out the best way to move seamlessly, bypassing every obstacle!</p>
          <p>Immerse yourself in realistic and cartoon-like challenges, where you'll play as skilled free-runners like Faith from Mirror's Edge. Run across rooftops, flip over gaps, and sprint to survive in high-security areas. Each athlete is a master of free-running, and your task is to take control and complete entire courses without getting caught.</p>
          <p>Train with the best competitors from American Ninja Warrior and learn how to move like the world's top free-runners. Push your limits, master new moves, and win the championship! With our parkour games, the excitement never stops. So, gear up, ready your body, and embark on a thrilling adventure through the urban jungle!</p>
        </div>
        <div v-if="gameType == '3D'">
          <p>Prepare to be captivated by our thrilling 3D games, designed for players who love great graphics and fast-paced action! Immerse yourself in a world of endless possibilities where you can modify star constellations, create custom images, and construct lifelike pictures. Our extensive collection boasts hundreds of challenging levels and images, each offering a unique puzzle to solve.</p>
          <p>With our 3D games, learning is fun and intuitive. Simply use your mouse to manipulate the image into the desired pattern, and you'll be well on your way to becoming an expert. Our in-game tutorials provide a step-by-step guide through the first few levels, ensuring a smooth learning curve.</p>
          <p>Choose from a variety of adventures, each offering a unique environment. Explore the mysteries of outer space or delve into an abstract video game land. Enjoy smooth gameplay and vibrant graphics that bring our collection to life.</p>
          <p>Compete against the clock and solve each puzzle quickly to advance to the next level and earn a high score. With thousands of points to earn, the challenge never ends. So, grab your mouse, ready your skills, and embark on a thrilling 3D adventure that will keep you hooked for hours!</p>
        </div>
        <div v-if="gameType == 'ADVENTURE'">
          <p>Our collection of adventure games caters to players of all skill levels, from novices to experts. Whether you enjoy simple point-and-click challenges or prefer wild, action-packed adventures, we have something for you. Embark on thrilling journeys that take you from your own backyard to distant continents and even outer space. With thousands of games to choose from, you'll never run out of exciting new experiences!</p>
          <p>Dive into the world of RPGs, where you can create your own character, build a team, and embark on epic quests. Or, test your skills in fast-paced action games where every move counts. If you prefer a more relaxed pace, try our puzzle and hidden object games that will challenge your mind.</p>
          <p>Our adventure collection also features modified versions of your favorite console games, as well as unique Flash games. Whether you're a music lover who wants to learn how to play instruments or a movie buff who wants to relive your favorite scenes, we have games for you.</p>
          <p>So, grab your controller or keyboard, and dive into the world of adventure gaming. Solve mysteries, make key decisions, and experience brand-new journeys every time you play. With our diverse collection of games, the possibilities are endless!</p>
        </div>
        <div v-if="gameType == 'ARCADE'">
          <p>Indulge in our extensive collection of the greatest arcade games ever created! Our library is a perfect blend of classic and contemporary titles, taking you from the original Pong to state-of-the-art 3D air hockey experiences. Embark on thrilling adventures in Space Invaders and Bomberman variations, while reliving the nostalgia of Nintendo and Sega classics like Mario and Sonic the Hedgehog.</p>
          <p>But our arcade collection doesn't just stop there. Venture into less conventional territories with Final Fantasy modifications, WALL-E themed games, and much more. Each title offers captivating gameplay and challenging levels, designed to test your skills and reward your dedication.</p>
          <p>With easy-to-learn controls, you'll be shooting lasers, maneuvering characters, and leaping across gaps in no time. Compete against others in Intense battles， earn points and virtual money, and become a master of the virtual arcade.</p>
          <p>So why wait? Start playing today and immerse yourself in a world of non-stop action and excitement. Our collection has everything you need to satisfy your arcade gaming desires. Let the games begin!</p>
        </div>
        <div v-if="gameType == 'SPORTS'">
          <p>Are you ready to take on the ultimate challenge in sports gaming? Our collection is the ultimate destination for athletes, fans, and all-star gamers alike. Whether you're a pro at billiards or just looking for a fun way to spend an afternoon, we have everything you need to get your game on.</p>
          <p>Step into the ring for a boxing match against friends or take your skills to the track for a thrilling horse racing experience. Team up with others for soccer or baseball matches, or go solo for extreme winter sports like skiing and sledding. The choices are endless!</p>
          <p>Compete against AI opponents or take on real players from around the world. Show them what you're made of in land, water, or air sports. Control your player's power and use it to your advantage, throwing punches, performing tackles, and leaping through the air with aggression.</p>
          <p>Practice your skills in multiplayer challenges or team up with others to take on even bigger opponents. Win individual games to climb the ranks in online tournaments and become a sports gaming legend.</p>
          <p>So, what are you waiting for? Grab your controller and dive into the world of sports gaming. Show us what you're made of and become the ultimate sports gamer today!</p>
        </div>
        <h3>What is the best free game online?</h3>
        <ol>
          <li v-for="(item,index) in allGameList.slice(0,10)" :key="index">
            <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(item)">
              {{item.Name}}
            </a>
          </li>
        </ol>
        <h3>What is the most popular game?</h3>
        <ol>
          <li v-for="(item,index) in allGameList.slice(0,5)" :key="index">
            <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(item)">
              {{item.Name}}
            </a>
          </li>
        </ol>
      </div>
    </div>
    <div style="display: contents" v-if="smegmaType">
      <div class="masking masking_1" style="background-color: #AEEBFE">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileLogo from '@/components/MobileLogo.vue';
import BottomList from "@/components/MobileTerminal/MobileHome/BottomList";
import ClassList from "@/components/MobileTerminal/MobileHome/ClassList";
import {
  advMouseenterPublic,
  clickGameLog,
  determinePcOrMove, iconClickEscalation,
  Observer,
  pageInitLog,
  pageOutLog,
  recentGame,
  shuffle
} from '@/utils/utils.js'
export default {
  name: "mobileClassifyIndex",
  components: {
    BottomList, ClassList, MobileLogo
  },
  data() {
    return {
      gameList: [],
      recommend: [],
      gameType: "", // title
      eZzVdABef: 'eZzVdABef',
      clientWidth: 0, // 屏幕宽度
      smegmaType: false,
      caPub: null,
      slot: null,
      noAdvertisements: null,
      mNoAfc: null,
      gptId: null,
      allGameList: [],
      newJumpUrl: null,
      jumpSubdomain: null
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { m_type_slot, channel_ca_pub, noAdvertisements, mNoAfc, m_type_gpt, jumpSubdomain } = channelInfo || {}
    this.slot = m_type_slot
    this.caPub = channel_ca_pub
    this.noAdvertisements = noAdvertisements
    this.mNoAfc = mNoAfc
      let gpt = m_type_gpt && m_type_gpt.split(",") || []
      this.gptId = gpt[3]
    this.jumpSubdomain = jumpSubdomain
    // 跳转子域名详情页
    this.newJumpUrl = window.location.protocol + '//' + 'game01' + '.' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    const { query } = this.$route
    const { gameType } = query || {}
    if (determinePcOrMove() == 2) {
      let { channel } = this.$route.query
      let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
      const { urlPCType } = channelInfo || {}
      this.$router.push({
        path: `${urlPCType ? '/Pc' : '/P'}/gameType`,
        query: {
          gameType,
          channel
        }
      },()=>{})
    } else {
      let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
      const { noAdvertisements, mNoAfc } = channelInfo || {}
      this.smegmaType = true
      // 进入页面埋点
      pageInitLog('mobile_tab')
      this.timer = setInterval(()=>{
        if (window.loadOk) {
          clearInterval(this.timer)
          this.smegmaType = false
          window.onresize = () => {
            this.clientWidth = document.body.clientWidth
            this.getGameList()
          }
          this.clientWidth = document.body.clientWidth
          this.getGameList()
          let arr = []
          getAllJson().map((item)=>{
            arr.push(item)
          })
          this.recommend = shuffle(arr).splice(0,30)
          setTimeout(()=>{
            if (!noAdvertisements && !mNoAfc) {
              window.addAds()
            }
          },500)
          // 获取需要曝光的item
          setTimeout(()=>{
            let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")]
            itemArr && Array.from(itemArr).map((item)=>{
              Observer('mobile_tab').observe(item)
            })
          })
        }
      },800)
        if (mNoAfc) {
            console.log(window.isDisplay);
            if (window.isDisplay) {
                googletag.cmd.push(function() { googletag.pubads().refresh(); });
            }
            let leftAdv = document.createElement("script")
            leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(mType[3]);window.isDisplay = true};});"
            setTimeout(()=>{
                this.$refs.advRef[0].append(leftAdv)
            },1000)
        }
    }
  },
  methods: {
    getGameList() {
      const { query } = this.$route
      const { gameType } = query || {}
      this.gameType = gameType
      let gameArr = getAllJson()
      let gameList = []
      let allGameList = []
      gameArr && gameArr.map((item)=>{
        if (item[gameType] == 1) {
          gameList.push(item)
          allGameList.push(item)
        }
      })
      this.allGameList = allGameList
      if (this.clientWidth < 550.9) {
        let newArr = [] // 新数组
        let num = Math.ceil(gameList.length / 12)
        for ( let i = 1; i <= num; i++ ) {
          newArr[i - 1] = gameList.splice(0,12)
        }
        this.gameList = newArr
      } else {
        this.gameList = gameList
      }
    },
    // 切换游戏
    iconClick (item) {
      // 打点
      clickGameLog('mobile_tab', item)
      // 离开页面埋点
      pageOutLog('mobile_tab')
      recentGame(item)
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'button', {configId: '903985143796275456'});
        bge && bge('event', 'form_button', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }
      iconClickEscalation()
    },
    advMouseenter() {
      advMouseenterPublic()
    }
  },
  // beforeRouteLeave(to, from, next){
  //   console.log(to);
  //   console.log(from);
  //   if (to.query.gameId) {
  //     getAllJson().map((item)=>{
  //       if (item.gameId == to.query.gameId) {
  //         // 打点
  //         clickGameLog('mobile_tab', item)
  //         recentGame(item)
  //         if (this.$originCopyWriting == 'ajoy') {
  //           bge && bge('event', 'button', {configId: '903985143796275456'});
  //           sessionStorage.setItem('pixelType','1')
  //         }
  //       }
  //     })
  //   }
  //   next()
  // },
  beforeDestroy() {
    // 离开页面埋点
    pageOutLog('mobile_tab')
  },
  watch: {
    '$route'(val) {
      console.log(val,'数据更新了');
      this.getGameList()
      let arr = []
      getAllJson().map((item)=>{
        arr.push(item)
      })
      this.recommend = shuffle(arr).splice(0,30)
    }
  }
}
</script>

<style lang="less" scoped>
@media (hover: hover){
  .a_style_2:hover {
    transform: scale(1.01869) translate(0px, -4px)!important;
  }
  .a_style_2:hover::after {
    opacity: 1!important;
    background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%)!important;
  }
  .a_style_2:hover .opacity {
    opacity: 1!important;
    transform: translate(0px, 0px)!important;
  }
}
@media (min-width: 111px) and (max-width: 550.9px) {
  .big_box, .type_box, .bottom {
    width: 314px;
  }
  .game_box {
    grid-template-areas:
          ". ibx ibx"
          ". bigIp0 bigIp0"
          ". bigIp0 bigIp0"
          ". . ."
          "bigIp6 bigIp6 ."
          "bigIp6 bigIp6 ."
  }
  .game_box_1 {
    grid-template-areas:
          ". ibx ibx"
          ". bigIp0 bigIp0"
          ". bigIp0 bigIp0"
          "adv adv adv"
          "adv adv adv"
          "adv adv adv"
          ". . ."
          "bigIp6 bigIp6 ."
          "bigIp6 bigIp6 ."
  }
  .type_style {
    grid-template-areas:
          ". ibx ibx"
          ". . ."
  }
}
@media (min-width: 551px) and (max-width: 660.9px) {
  .big_box, .type_box, .bottom {
    width: 534px;
  }
  .game_box {
    --gridTemplateColumns: 5!important;
    grid-template-areas:
        ". ibx ibx bigIp0 bigIp0"
        ". . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 . . ."
  }
  .game_box_1 {
    --gridTemplateColumns: 5!important;
    grid-template-areas:
        ". ibx ibx bigIp0 bigIp0"
        ". . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 . . ."
        "adv adv adv adv adv"
        "adv adv adv adv adv"
        "adv adv adv adv adv"
  }
  .type_style {
    --gridTemplateColumns: 5!important;
    grid-template-areas:
          ". ibx ibx . ."
          ". . . . ."
  }
}
@media (min-width: 661px) and (max-width: 880.9px) {
  .big_box, .type_box, .bottom {
    width: 644px;
  }
  .game_box {
    --gridTemplateColumns: 6!important;
    grid-template-areas:
        ". ibx ibx . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . . ."
  }
  .game_box_1 {
    --gridTemplateColumns: 6!important;
    grid-template-areas:
        ". ibx ibx . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . . ."
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
  }
  .type_style {
    --gridTemplateColumns: 6!important;
    grid-template-areas:
          ". ibx ibx . . ."
          ". . . . . ."
  }
}
@media (min-width: 881px) {
  .big_box, .type_box, .bottom {
    width: 864px;
    .game_box {
      --gridTemplateColumns: 8!important;
      grid-template-areas:
        ". ibx ibx . . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . . . . ."
    }
    .game_box_1 {
      --gridTemplateColumns: 8!important;
      grid-template-areas:
        ". ibx ibx . . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . . . bigIp0 bigIp0"
        "bigIp1 bigIp1 . . . . . ."
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
    }
    .type_style {
      --gridTemplateColumns: 8!important;
      grid-template-areas:
          ". ibx ibx . . . . ."
          ". . . . . . . ."
    }
  }
}
#mobile_classify{
  width: 100%;
  height: auto;
  .game-name{
    background: white;
    border-radius: 16px;
    padding: 10px 16px;
    box-sizing: border-box;
    h1{
      font: 500 20px/24px Torus, sans-serif;
      color: #002b50;
      margin: 0px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    align-items: center;
    font-size: 21px;
  }
  .content{
    .big_box, .type_box{
      margin: 0px auto;
      flex-grow: 1;
    }
    .eZzVdABef .game_box::before{
      content: "";
      display: block;
    }
    .eZzVdABef .game_box_1::before{
      content: "";
      display: block;
    }
    .type_box .type_style::before{
      content: "";
      display: block;
    }
    .game_box, .type_style, .game_box_1 {
      display: grid;
      grid-template-rows: repeat(auto-fill, 94px);
      grid-gap: 16px;
      grid-auto-flow: dense;
      justify-content: center;
      margin: 16px auto 0px;
      padding: 0px;
      list-style-type: none;
      --gridTemplateColumns: 3;
      grid-template-columns: repeat(var(--gridTemplateColumns),94px);
    }
    .img_style {
      display: block;
      --minSize: 94px;
      min-width: var(--minSize);
      min-height: var(--minSize);
      border-radius: inherit;
      aspect-ratio: 1 / 1;
    }
    .a_style_2 {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      user-select: none;
      aspect-ratio: 1 / 1;
    }
    .a_style_1 {
      display: block;
      transition: transform .6s cubic-bezier(.25, .1, .25, 1);
      border-radius: 16px;
      position: relative;
    }
    .a_style_1:hover {
      transform: scale(1.04255) translate(0px, -4px);
      transition-duration: 0.3s;
    }
    .a_style_1::after {
      content: "";
      opacity: 0;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
      z-index: 4;
      transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1),opacity .3s cubic-bezier(.25, .1, .25, 1);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      border-radius: 16px;
      contain: strict;
    }
    .position {
      position: absolute;
      right: 0px;
      bottom: 0px;
      left: 0px;
      padding: 6px;
      color: rgb(255, 255, 255);
      font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
      text-align: center;
      z-index: 6;
      transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
      transform: translate(0px, 8px);
      opacity: 0;
      -webkit-font-smoothing: antialiased;
      pointer-events: none;
      word-break: break-all;
    }
  }
  .bottom{
    margin: 32px auto 0;
    .bottom-text {
      position: relative;
      margin: 30px 0;
      padding: 18px 24px;
      background: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      h1{
        font: 500 24px Torus,sans-serif;
      }
      p{
        margin: 24px 0;
        font: 400 16px/24px Proxima Nova,Open Sans,Gill Sans MT,Gill Sans,Arial,sans-serif;
        color: #002b50;
      }
      a{
        color: #054a91;
        text-decoration: underline;
      }
      h3{
        color: #002b50;
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
      h4{
        margin-top: 10px;
        color: #002b50;
      }
      ul,ol{
        padding-left: 40px;
        li{
          margin-bottom: 5px;
          color: #002b50;
          font-size: 16px;
        }
      }
      .about{
        color: #002b50;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
        margin: 3px 0 0;
        text-transform: uppercase;
        ul{
          list-style: none;
          margin: 0;
          overflow: hidden;
          padding: 0;
          text-overflow: ellipsis;
          li{
            display: inline;
            font-size: 9px;
            a{
              font-size: 9px;
              letter-spacing: 1px;
              text-transform: uppercase;
              white-space: nowrap;
              color: #002b50;
              text-decoration: none;
            }
          }
          li:nth-child(2)::before{
            content: "›";
            font-size: 13px;
            margin: 0 4px;
          }
        }
      }
      .title{
        font-size: 20px;
        color: #002b50;
        margin-top: 10px;
        h2{
          font: 500 20px Torus,sans-serif;
        }
      }
    }
  }
  @keyframes djqRmU{
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  @keyframes etkMLf{
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  .masking_1{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: djqRmU .2s cubic-bezier(.25, .1, .25, 1) both;
    z-index: 100001;
  }
  .masking{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 18px;
      height: 18px;
      margin: 0 2px;
      border-radius: 100%;
      background: white;
      animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
    }
    div:nth-child(2){
      animation-delay: -0.16s;
    }
  }
}
</style>
