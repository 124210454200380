import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    deferredPrompt: null, // PWA是否安装
    deferredPromptType: false, // 安装PWA按钮状态
    fullScreenOrNot: false, // 是否全屏
    installType: '1', // 展示首页下载安装卡片状态
    installPageClick: null, // null默认浏览器安装1pc首页安装2pc详情页安装3mobile首页安装
    pageHiddenType: false, // 窗口是否在后台
  },
  getters: {
  },
  mutations: {
    changePWA(state,data) {
      state.deferredPrompt = data.deferredPrompt
      state.deferredPromptType = data.deferredPromptType
    },
    changeScreen(state, data) {
      console.log(data);
      state.fullScreenOrNot = data
    },
    changeInstallType(state, data) {
      state.installType = data
    },
    changeInstallPageClick(state, data) {
      state.installPageClick = data
    },
    changePageHiddenTypeClick(state, data) {
      state.pageHiddenType = data
    }
  },
  actions: {
  },
  modules: {
  }
})
