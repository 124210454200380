<template>
  <div class="bottom-list">
    <a
        :href="jumpSubdomain && whereFrom == 2 ? (newJumpUrl + '/M/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): ''))"
        class="item bl-963fcq-1"
        v-for="(item,index) in typeList"
        :id="item.type"
        :key="index"
        @click="classClick(item.type)">
      <img v-lazy="item.iconUrl" alt="">
    </a>
  </div>
</template>

<script>
import {ClassificationObserver, clickClassificationLog, getGameTypeList} from '@/utils/utils'
export default {
  name: "BottomList",
  props: ["whereFrom"],// whereFrom 1首页 2详情页 3类型页
  data() {
    return {
      typeList: [],
      newJumpUrl: null,
      jumpSubdomain: null,
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { jumpSubdomain } = channelInfo || {}
    this.jumpSubdomain = jumpSubdomain
    this.newJumpUrl = window.location.protocol + '//' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    this.typeList = getGameTypeList() || []
    // 获取需要曝光的item
    setTimeout(()=>{
      let itemArr = [...document.getElementsByClassName("bl-963fcq-1")]
      let portal = this.whereFrom == 1 ? 'mobile_home' : this.whereFrom == 2 ? 'mobile_detail' : 'mobile_tab'
      itemArr && Array.from(itemArr).map((item)=>{
        ClassificationObserver(portal).observe(item)
      })
    })
  },
  methods: {
    classClick(gameType) {
      // 点击类型打点
      let portal = this.whereFrom == 1 ? 'mobile_home' : this.whereFrom == 2 ? 'mobile_detail' : 'mobile_tab'
      clickClassificationLog(portal,gameType)
    }
  }
}
</script>

<style lang="less" scoped>
.bottom-list{
  margin-top: 16px;
  display: grid;
  grid-template-rows: repeat(auto-fill,80px);
  grid-template-columns: repeat(auto-fill,150px);
  grid-gap: 11px;
  grid-auto-flow: dense;
  justify-content: center;
  padding: 16px 0;
  .item{
    width: 150px;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
}
</style>
