<template>
  <div class="big-box">
    <PCLogo whereFrom="1" portal="pc_home"></PCLogo>
    <div class="content_box">
      <div :class="styleAdjust ? 'games_box_3' : (rightSlot2 || gtpLeftId) ? 'games_box_2' : 'games_box'">
        <div style="display: contents">
          <div class="rightAdv adv" style="grid-area: rightAdv">
            <div class="adv_box">
              <div class="adv_box_1" style="height: 250px; width: 300px; overflow: hidden;">
                <div v-if="noAfc" :id="gtpRightId" ref="rightAdv"></div>
                <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                     style="display:block;width: 300px;height: 250px"
                     :data-ad-client="caPub"
                     :data-ad-slot="rightSlot"
                     data-ad-format="true"
                     data-full-width-responsive="true"></ins>
              </div>
            </div>
          </div>
          <div class="bottomAdv adv" style="grid-area: bottomAdv">
            <div class="adv_box">
              <div :style="{width: '728px',height: '90px',overflow: 'hidden'}" class="adv_box_1">
                <div v-if="noAfc" :id="gtpLeftId" ref="leftAdv"></div>
                <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                     :style="{display:'block',width: '728px',height: '90px'}"
                     :data-ad-client="caPub"
                     :data-ad-slot="bottomSlot"
                     data-ad-format="true"
                     data-full-width-responsive="true"></ins>
              </div>
            </div>
          </div>
        </div>
        <div style="display: contents" v-if="rightSlot2 || gtpRightId2">
          <div class="rightAdv adv" style="grid-area: rightAdv2">
            <div class="adv_box">
              <div class="adv_box_1" style="height: 250px; width: 300px; overflow: hidden;">
                <div v-if="noAfc" :id="gtpRightId2" ref="rightAdv2"></div>
                <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                     style="display:block;width: 300px;height: 250px"
                     :data-ad-client="caPub"
                     :data-ad-slot="rightSlot2"
                     data-ad-format="true"
                     data-full-width-responsive="true"></ins>
              </div>
            </div>
          </div>
          <div class="bottomAdv adv" style="grid-area: bottomAdv2">
            <div class="adv_box">
              <div :style="{width: '728px',height: '90px',overflow: 'hidden'}" class="adv_box_1">
                <div v-if="noAfc" :id="gtpLeftId2" ref="leftAdv2"></div>
                <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                     :style="{display:'block',width: '728px',height: '90px'}"
                     :data-ad-client="caPub"
                     :data-ad-slot="bottomSlot2"
                     data-ad-format="true"
                     data-full-width-responsive="true"></ins>
              </div>
            </div>
          </div>
        </div>
        <div style="display: contents">
          <div class="top_game" style="grid-area: topGame">
            <div class="top_box">
              <img :src="trophy" alt="">
              <span class="title">Top Games</span>
              <p class="title_bj"></p>
            </div>
            <div class="top_game_list">
              <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" v-for="(item,index) in topGame" :key="index"
                 class="sc-wr3rvk-0 top_game_icon"
                 @click="iconClick(item)">
                <p>
                  <img v-lazy="item.iconUrl" alt="">
                </p>
                <h3>{{ item.Name }}</h3>
                <div class="bj_1"></div>
                <div class="bj_2"></div>
              </a>
            </div>
          </div>
        </div>
        <div style="display: contents">
          <div class="top_game" style="grid-area: newGame">
            <div class="top_box">
              <img :src="newGame" alt="">
              <span class="title">New Games</span>
              <p class="title_bj"></p>
            </div>
            <div class="top_game_list">
              <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId) : ((urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" v-for="(item,index) in newGameList" :key="index"
                 class="sc-wr3rvk-0 top_game_icon"
                 @click="iconClick(item)">
                <p>
                  <img v-lazy="item.iconUrl" alt="">
                </p>
                <h3>{{ item.Name }}</h3>
                <div class="bj_1"></div>
                <div class="bj_2"></div>
              </a>
            </div>
          </div>
        </div>
        <div style="display: contents">
          <ul class="big_img_box">
            <li v-for="(item,index) in bigImg" :key="index">
              <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" class="sc-wr3rvk-0 a_style_1 a_style_2"
                 :style="{gridArea: 'bigIp' + index}" @click="iconClick(item)">
                <img v-lazy="item.iconUrl" alt="" width="314px" height="314px" class="img_style">
                <span class="opacity position">{{ item.Name }}</span>
                <div class="visible video_box" @mouseenter="playVid(index)" @mouseleave="pauseVid(index)">
                  <video preload="none" loop class="video_style_1 video_style">
                    <source :src="item.VideoUrl" type="video/mp4">
                  </video>
                </div>
                <div class="hot_box" v-if="item.hotType">
                  <div class="hot_img">
                    <img :src="huore" alt="">
                  </div>
                </div>
                <div class="flash_box" v-if="item.Flash == 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" class="sc-15wj1kq-1 cYmAAh">
                    <defs>
                      <linearGradient id="lgrad" x1="0%" y1="100%" x2="100%" y2="0%">
                        <stop offset="0%" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
                        <stop offset="25%" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
                        <stop offset="50%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                        <stop offset="75%" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
                        <stop offset="100%" style="stop-color: rgb(238, 130, 238); stop-opacity: 0;"></stop>
                      </linearGradient>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#lgrad)"></rect>
                  </svg>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div style="display: contents">
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" v-for="(item,index) in centreImg" :key="index"
             class="sc-wr3rvk-0 a_style_1 a_style_2"
             :style="{gridArea: 'ip' + (index + 3)}" @click="iconClick(item)">
            <img v-lazy="item.iconUrl" alt="" width="204px" height="204px" class="img_style">
            <span class="opacity position">{{ item.Name }}</span>
            <div class="visible video_box" @mouseenter="playVid(index + 3)" @mouseleave="pauseVid(index + 3)">
              <video preload="none" loop class="video_style_1 video_style">
                <source :src="item.VideoUrl" type="video/mp4">
              </video>
            </div>
            <div class="hot_box" v-if="item.hotType">
              <div class="hot_img">
                <img :src="huore" alt="">
              </div>
            </div>
          </a>
        </div>
        <div style="display: contents">
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" v-for="(item,index) in smallImg" :key="index"
             class="sc-wr3rvk-0 a_style_1 a_style_2" @click="iconClick(item)" :style="styleAdjust ? {borderRadius: 0} : null">
            <img v-lazy="item.iconUrl" alt="" width="94px" height="94px" class="img_style">
            <span class="opacity position">{{ item.Name }}</span>
            <div class="hot_box" v-if="item.hotType">
              <div class="hot_img">
                <img :src="huore" alt="">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="type-list">
      <TypeList whereFrom="2"></TypeList>
      <div class="middle_title" :style="styleAdjust ? {background: `url(${middleBj}) no-repeat`,color: '#ffffff'} : {background: `url(${middleBj}) no-repeat`}">
        About {{$headToUpperCase && $headToUpperCase}}
      </div>
      <div class="bottom-text">
        <div class="title"><h1>Welcome to {{$headToUpperCase && $headToUpperCase}}!</h1></div>
        <p>Unlock endless fun at {{$headToUpperCase && $headToUpperCase}} - the ultimate destination for free online games! With a vast selection of games that require no downloads, logins, or annoying distractions, we offer an unparalleled gaming experience. Play alone or with friends on any device, whether you're at home or on the go. Get ready to immerse yourself in the excitement and embark on an unforgettable gaming journey. Experience the best – only at {{$headToUpperCase && $headToUpperCase}}!</p>
        <h3>Trending Games Collection</h3>
        <p>Experience new games every day on our platform! Play popular titles like
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[0] && allGames[0].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[0] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[0] && allGames[0].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[0] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[0])">{{allGames && allGames[0] && allGames[0].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[1] && allGames[1].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[1] && allGames[1].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[1] && allGames[1].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[1] && allGames[1].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[1])">{{allGames && allGames[1] && allGames[1].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[2] && allGames[2].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[2] && allGames[2].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[2] && allGames[2].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[2] && allGames[2].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[2])">{{allGames && allGames[2] && allGames[2].Name}}</a>
          and
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[3] && allGames[3].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[3] && allGames[3].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[3] && allGames[3].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[3] && allGames[3].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[3])">{{allGames && allGames[3] && allGames[3].Name}}</a>
          on {{$headToUpperCase && $headToUpperCase}}. Enjoy classics like
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[4] && allGames[4].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[4] && allGames[4].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[4] && allGames[4].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[4] && allGames[4].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[4])">{{allGames && allGames[4] && allGames[4].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[5] && allGames[5].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[5] && allGames[5].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[5] && allGames[5].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[5] && allGames[5].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[5])">{{allGames && allGames[5] && allGames[5].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[6] && allGames[6].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[6] && allGames[6].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[6] && allGames[6].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[6] && allGames[6].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[6])">{{allGames && allGames[6] && allGames[6].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[7] && allGames[7].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[7] && allGames[7].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[7] && allGames[7].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[7] && allGames[7].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[7])">{{allGames && allGames[7] && allGames[7].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[8] && allGames[8].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[8] && allGames[8].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[8] && allGames[8].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[8] && allGames[8].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[8])">{{allGames && allGames[8] && allGames[8].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[9] && allGames[9].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[9] && allGames[9].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[9] && allGames[9].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[9] && allGames[9].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[9])">{{allGames && allGames[9] && allGames[9].Name}}</a>
          and
          <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[10] && allGames[10].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[10] && allGames[10].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+(allGames && allGames[10] && allGames[10].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[10] && allGames[10].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[10])">{{allGames && allGames[10] && allGames[10].Name}}</a>
          for free. With over 2000 games to choose from, the excitement never stops at {{$headToUpperCase && $headToUpperCase}}!
        </p>
        <h3>Pick Your Favorite</h3>
        <p>Discover the perfect game for you at {{$headToUpperCase && $headToUpperCase}}! Explore our homepage or choose from popular categories. Action, puzzles, racing, strategy - we've got it all! Let the games begin!</p>
        <ul>
          <li v-for="(item,index) in typeList.slice(0,6)" :key="index">
            <a :href="(urlPCType ? '/Pc' : '/P') + '/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="classClick(item.type)">
              {{item.type.slice(0,1).toUpperCase() + item.type.slice(1).toLowerCase()}} Games
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TypeList from '@/components/TypeList.vue';
import {clickGameLog, recentGame, Observer, pageOutLog, iconClickEscalation, getGameTypeList, clickClassificationLog} from '@/utils/utils.js';
import logo from '@/assets/logo.png';
import home from '@/assets/home.png';
import souSuo from '@/assets/sousuo.png';
import huore from '@/assets/huore.png';
import middleBj from '@/assets/middel_bj.png';
import trophy from '@/assets/trophy.png';
import newGame from '@/assets/newgame.png';

import PCLogo from "@/components/PCLogo.vue";
export default {
  name: "contentIndex",
  components: {
    TypeList, PCLogo
  },
  data() {
    return {
      bigImg: [], // 大图片
      centreImg: [], // 中图片
      smallImg: [], // 小图片
      logo,
      home,
      souSuo,
      middleBj,
      trophy,
      newGame,
      videoDiv: null,
      UnfoldAndCollapse: false, // 展开收起
      clientWidth: 0, // 屏幕宽度
      huore,
      noAdvertisements: null, // 1没广告
      noAfc: null, // 1gtp
      gtpLeftId: '',
      gtpLeftId2: '',
      gtpRightId: '',
      gtpRightId2: '',
      caPub: '',
      rightSlot: '',
      rightSlot2: '',
      bottomSlot: '',
      bottomSlot2: '',
      allGames: [], // 全部游戏
      typeList: [],
      topGame: [],
      newGameList: [],
      styleAdjust: null,
      urlPCType: null, // 跳转路径为Pc
      newJumpUrl: null,
      jumpSubdomain: null
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements, noAfc, p_home_gpt_left, p_home_gpt_left2, p_home_gpt_right, p_home_gpt_right2, channel_ca_pub, p_home_right, p_home_bottom, p_home_right2, p_home_bottom2, styleAdjust, urlPCType, jumpSubdomain } = channelInfo || {}
    this.noAdvertisements = noAdvertisements
    this.noAfc = noAfc
    let gtpL = p_home_gpt_left && p_home_gpt_left.split(",") || []
    let gtpL2 = p_home_gpt_left2 && p_home_gpt_left2.split(",") || []
    let gtpR = p_home_gpt_right && p_home_gpt_right.split(",") || []
    let gtpR2 = p_home_gpt_right2 && p_home_gpt_right2.split(",") || []
    this.gtpLeftId = gtpL[3]
    this.gtpLeftId2 = gtpL2[3]
    this.gtpRightId = gtpR[3]
    this.gtpRightId2 = gtpR2[3]
    this.caPub = channel_ca_pub
    this.rightSlot = p_home_right
    this.rightSlot2 = p_home_right2
    this.bottomSlot = p_home_bottom
    this.bottomSlot2 = p_home_bottom2
    this.styleAdjust = styleAdjust
    this.urlPCType = urlPCType
    this.jumpSubdomain = jumpSubdomain
    // 跳转子域名详情页
    this.newJumpUrl = window.location.protocol + '//' + 'game01' + '.' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements, noAfc, p_home_gpt_left2 } = channelInfo || {}
    window.onresize = () => {
      this.clientWidth = document.body.clientWidth
    }
    this.clientWidth = document.body.clientWidth
    setTimeout(()=>{
      if (!noAdvertisements && !noAfc) {
        window.addAds()
      }
    },800)
    this.typeList = getGameTypeList() || []
    this.getJson()
    this.videoDiv = document.getElementsByClassName('video_style_1')
    // 获取需要曝光的item
    setTimeout(()=>{
      let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")]
      itemArr && Array.from(itemArr).map((item)=>{
        Observer('pc_home').observe(item)
      })
    })

    if (noAfc) {
      console.log(window.isDisplay);
      if (window.isDisplay) {
        googletag.cmd.push(function() { googletag.pubads().refresh(); });
      }

      let leftAdv = document.createElement("script")
      leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(homePcl[3]);};});"
      setTimeout(()=>{
        this.$refs.leftAdv.append(leftAdv)
      },600)

      let rightAdv = document.createElement("script")
      rightAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(homePcr[3])};});"
      setTimeout(()=>{
        this.$refs.rightAdv.append(rightAdv)
      },900)
      if (p_home_gpt_left2) {
        let leftAdv2 = document.createElement("script")
        leftAdv2.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(homePcl2[3]);};});"
        setTimeout(()=>{
          this.$refs.leftAdv2.append(leftAdv2)
        },1200)

        let rightAdv2 = document.createElement("script")
        rightAdv2.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(homePcr2[3]);window.isDisplay = true};});"
        setTimeout(()=>{
          this.$refs.rightAdv2.append(rightAdv2)
        },900)
      }
    }
  },
  methods: {
    getJson() {
      document.documentElement.scrollTop = 0
      let bigImg = []
      let centreImg = []
      let topGame = []
      let smallImg = []
      let newGameList = []
      getAllJson() && getAllJson().map((item)=>{
        newGameList.push(item)
      })
      newGameList.sort(function (a,b) {
        return b.gameId - a.gameId
      })
      let arr = getAllJson().slice(0,161)
      arr && arr.map((item)=>{
        if (this.styleAdjust) {
          if (item.ImgSize == 2 && topGame.length < 3) {
            topGame.push(item)
          } else {
            smallImg.push(item)
          }
        } else {
          if (item.ImgSize == 1) {
            bigImg.push(item)
          } else if (item.ImgSize == 2) {
            centreImg.push(item)
          } else {
            smallImg.push(item)
          }
        }
      })
      this.bigImg = bigImg
      this.topGame = this.styleAdjust ? topGame : centreImg.splice(0,3)
      this.centreImg = centreImg
      this.smallImg = smallImg
      this.allGames = arr
      this.newGameList = newGameList.splice(0,3)
    },
    // 跳转分类
    classClick(type) {
      clickClassificationLog('pc_home',type)
    },
    // 鼠标移入播放视频
    playVid(index) {
      this.videoDiv[index].muted = true
      let playPromise = this.videoDiv[index].play()
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // // 这个时候可以安全的暂停
          // this.videoDiv[index].pause();
        })
            .catch(() => {

            });
      }
    },
    // 鼠标移入播放视频
    pauseVid(index) {
      this.videoDiv[index].muted = false
      this.videoDiv[index].load()
      this.videoDiv[index].pause()
    },
    // 点击跳转详情
    iconClick(item) {
      clickGameLog('pc_home', item)
      recentGame(item)
      // 离开页面埋点
      pageOutLog('pc_home')
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'button', {configId: '903985143796275456'});
        bge && bge('event', 'form_button', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }
      iconClickEscalation()
    },
  },
  watch: {
    '$route'(val) {
      console.log(val, '数据更新了');
      this.getJson()
      // this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
@media (min-width: 1871px) {
  .type-list, .middle_title {
    width: 1854px;
  }

  .content_box {
    width: 1854px;

    .games_box {
      --gridTemplateColumns: 17;
      grid-template-areas:
        ". . . . . . . . bigIp0 bigIp0 bigIp0 . . ip3 ip3 . ."
        "topGame topGame topGame topGame ip4 ip4 ip5 ip5 bigIp0 bigIp0 bigIp0 . . ip3 ip3 . ."
        "topGame topGame topGame topGame ip4 ip4 ip5 ip5 bigIp0 bigIp0 bigIp0 ip6 ip6 . rightAdv rightAdv rightAdv"
        "ip7 ip7 bigIp1 bigIp1 bigIp1 ip8 ip8 . . . . ip6 ip6 . rightAdv rightAdv rightAdv"
        "ip7 ip7 bigIp1 bigIp1 bigIp1 ip8 ip8 . ip9 ip9 . . . . rightAdv rightAdv rightAdv"
        ". . bigIp1 bigIp1 bigIp1 . . . ip9 ip9 . . ip10 ip10 . . ."
        ". . . . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ip10 ip10 . . ."
        ". . ip11 ip11 . . ip12 ip12 . . . . . . . ip13 ip13"
        ". . ip11 ip11 . . ip12 ip12 bigIp2 bigIp2 bigIp2 newGame newGame newGame newGame ip13 ip13"
        "ip14 ip14 . . . . . . bigIp2 bigIp2 bigIp2 newGame newGame newGame newGame . ."
        "ip14 ip14 . . ip15 ip15 . . bigIp2 bigIp2 bigIp2 ip16 ip16 . . . ."
        ". . . . ip15 ip15 . . . . . ip16 ip16 . . . ."
    }

    .games_box_2 {
      --gridTemplateColumns: 17;
      grid-template-areas:
        ". . . . . . . . bigIp0 bigIp0 bigIp0 . . ip3 ip3 . ."
        "topGame topGame topGame topGame ip4 ip4 ip5 ip5 bigIp0 bigIp0 bigIp0 . . ip3 ip3 . ."
        "topGame topGame topGame topGame ip4 ip4 ip5 ip5 bigIp0 bigIp0 bigIp0 ip6 ip6 . rightAdv rightAdv rightAdv"
        "ip7 ip7 bigIp1 bigIp1 bigIp1 ip8 ip8 . . . . ip6 ip6 . rightAdv rightAdv rightAdv"
        "ip7 ip7 bigIp1 bigIp1 bigIp1 ip8 ip8 . ip9 ip9 . . . . rightAdv rightAdv rightAdv"
        ". . bigIp1 bigIp1 bigIp1 . . . ip9 ip9 . . ip10 ip10 . . ."
        ". . . . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ip10 ip10 . . ."
        ". . ip11 ip11 . . ip12 ip12 . . . . . . . ip13 ip13"
        ". . ip11 ip11 . . ip12 ip12 . . newGame newGame newGame newGame . ip13 ip13"
        "ip14 ip14 . . . . . . . . newGame newGame newGame newGame rightAdv2 rightAdv2 rightAdv2"
        "ip14 ip14 . . ip15 ip15 . bigIp2 bigIp2 bigIp2 . ip16 ip16 . rightAdv2 rightAdv2 rightAdv2"
        ". . . . ip15 ip15 . bigIp2 bigIp2 bigIp2 . ip16 ip16 . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . bigIp2 bigIp2 bigIp2 . . . . . . ."
        ". . . . . bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . . . . .";
    }

    .games_box_3 {
      --gridTemplateColumns: 17;
      grid-template-areas:
        ". . . . . . . . . . . . . . . . ."
        "topGame topGame topGame topGame . . . . . . . . . . . . ."
        "topGame topGame topGame topGame . . . . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . . . . . . . . ."
        ". . . . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ip10 ip10 . . ."
        ". . . . . . . . . . . . . . . . ."
        ". . . . . . . . . . newGame newGame newGame newGame . . ."
        ". . . . . . . . . . newGame newGame newGame newGame rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . . rightAdv2 rightAdv2 rightAdv2"
    }

    .games_box::before {
      grid-column-start: span 2;
    }
    .games_box_2::before {
      grid-column-start: span 2;
    }
    .games_box_3::before {
      grid-column-start: span 2;
    }
  }
}

@media (min-width: 1541px) and (max-width: 1870.9px) {
  .type-list, .middle_title {
    width: 1524px;
  }

  .content_box {
    width: 1524px;

    .games_box {
      --gridTemplateColumns: 14;
      grid-template-areas:
        ". . . . . ip3 ip3 . bigIp0 bigIp0 bigIp0 ip4 ip4 ."
        "topGame topGame topGame topGame . ip3 ip3 . bigIp0 bigIp0 bigIp0 ip4 ip4 ."
        "topGame topGame topGame topGame ip5 ip5 . . bigIp0 bigIp0 bigIp0 rightAdv rightAdv rightAdv"
        ". . . . ip5 ip5 bigIp1 bigIp1 bigIp1 . . rightAdv rightAdv rightAdv"
        "ip6 ip6 ip7 ip7 . . bigIp1 bigIp1 bigIp1 ip8 ip8 rightAdv rightAdv rightAdv"
        "ip6 ip6 ip7 ip7 . . bigIp1 bigIp1 bigIp1 ip8 ip8 . . ."
        ". . ip9 ip9 bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ip10 ip10 ."
        "ip11 ip11 ip9 ip9 . . . . . . . ip10 ip10 ."
        "ip11 ip11 . . . ip12 ip12 newGame newGame newGame newGame ip13 ip13 ."
        "bigIp2 bigIp2 bigIp2 ip14 ip14 ip12 ip12 newGame newGame newGame newGame ip13 ip13 ."
        "bigIp2 bigIp2 bigIp2 ip14 ip14 . . . . . . . . ."
        "bigIp2 bigIp2 bigIp2 . . . . ip15 ip15 . . . . ."
        ". . . . . . . ip15 ip15 . . ip16 ip16 ."
    }

    .games_box_2 {
      --gridTemplateColumns: 14;
      grid-template-areas:
        ". . . . . ip3 ip3 . bigIp0 bigIp0 bigIp0 ip4 ip4 ."
        "topGame topGame topGame topGame . ip3 ip3 . bigIp0 bigIp0 bigIp0 ip4 ip4 ."
        "topGame topGame topGame topGame ip5 ip5 . . bigIp0 bigIp0 bigIp0 rightAdv rightAdv rightAdv"
        ". . . . ip5 ip5 bigIp1 bigIp1 bigIp1 . . rightAdv rightAdv rightAdv"
        "ip6 ip6 ip7 ip7 . . bigIp1 bigIp1 bigIp1 ip8 ip8 rightAdv rightAdv rightAdv"
        "ip6 ip6 ip7 ip7 . . bigIp1 bigIp1 bigIp1 ip8 ip8 . . ."
        ". . ip9 ip9 bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ip10 ip10 ."
        "ip11 ip11 ip9 ip9 . . . . . . . ip10 ip10 ."
        "ip11 ip11 . . . ip12 ip12 . . . . . . ."
        "bigIp2 bigIp2 bigIp2 ip13 ip13 ip12 ip12 newGame newGame newGame newGame rightAdv2 rightAdv2 rightAdv2"
        "bigIp2 bigIp2 bigIp2 ip13 ip13 . . newGame newGame newGame newGame rightAdv2 rightAdv2 rightAdv2"
        "bigIp2 bigIp2 bigIp2 . ip14 ip14 . ip15 ip15 . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . ip14 ip14 . ip15 ip15 . . ip16 ip16 ."
        ". . . . bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 ip16 ip16 ."
    }

    .games_box_3 {
      --gridTemplateColumns: 14;
      grid-template-areas:
        ". . . . . . . . . . . . . ."
        "topGame topGame topGame topGame . . . . . . . . . ."
        "topGame topGame topGame topGame . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . . . . . ."
        ". . . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . . ."
        ". . . . . . . . . . . . . ."
        ". . . . . . . . . . . . . ."
        ". . . . . . . newGame newGame newGame newGame rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . newGame newGame newGame newGame rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . . . . . ."
        ". . . . bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . . ."
    }
  }
}

@media (min-width: 1321px) and (max-width: 1540.9px) {
  .type-list, .middle_title {
    width: 1304px;
  }

  .content_box {
    width: 1304px;

    .games_box {
      --gridTemplateColumns: 12;
      grid-template-areas:
        ". . . . ip3 ip3 . . ip4 ip4 ip5 ip5"
        "topGame topGame topGame topGame ip3 ip3 . . ip4 ip4 ip5 ip5"
        "topGame topGame topGame topGame bigIp0 bigIp0 bigIp0 . . rightAdv rightAdv rightAdv"
        ". . . . bigIp0 bigIp0 bigIp0 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 . . bigIp0 bigIp0 bigIp0 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . . ."
        ". . . . . . . . . . ip8 ip8"
        ". bigIp1 bigIp1 bigIp1 . newGame newGame newGame newGame . ip8 ip8"
        ". bigIp1 bigIp1 bigIp1 . newGame newGame newGame newGame . . ."
        ". bigIp1 bigIp1 bigIp1 . . . . . . . ."
        ". . ip9 ip9 . . . . . ip10 ip10 ."
        "ip11 ip11 ip9 ip9 . . bigIp2 bigIp2 bigIp2 ip10 ip10 ."
        "ip11 ip11 . . . . bigIp2 bigIp2 bigIp2 . . ."
        ". . . . ip12 ip12 bigIp2 bigIp2 bigIp2 . . ."
        ". ip13 ip13 . ip12 ip12 . . . . . ."
        ". ip13 ip13 . . . . . . . . ."
        ". . . . . . . . . . ip14 ip14"
        ". . . . . . ip15 ip15 . . ip14 ip14"
        ". . ip16 ip16 . . ip15 ip15 . . . ."
        ". . ip16 ip16 . . . . . . . ."
    }
    .games_box_2 {
      --gridTemplateColumns: 12;
      grid-template-areas:
        ". . . . ip3 ip3 . . ip4 ip4 ip5 ip5"
        "topGame topGame topGame topGame ip3 ip3 . . ip4 ip4 ip5 ip5"
        "topGame topGame topGame topGame bigIp0 bigIp0 bigIp0 . . rightAdv rightAdv rightAdv"
        ". . . . bigIp0 bigIp0 bigIp0 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 . . bigIp0 bigIp0 bigIp0 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . . ."
        ". . . . . . . . . . ip8 ip8"
        ". bigIp1 bigIp1 bigIp1 . newGame newGame newGame newGame . ip8 ip8"
        ". bigIp1 bigIp1 bigIp1 . newGame newGame newGame newGame . . ."
        ". bigIp1 bigIp1 bigIp1 . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . ip9 ip9 . . . . . rightAdv2 rightAdv2 rightAdv2"
        "ip10 ip10 ip9 ip9 . . bigIp2 bigIp2 bigIp2 rightAdv2 rightAdv2 rightAdv2"
        "ip10 ip10 . . . . bigIp2 bigIp2 bigIp2 . ip11 ip11"
        ". . . . . . bigIp2 bigIp2 bigIp2 . ip11 ip11"
        ". . bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . . ."
        ". ip12 ip12 . ip13 ip13 . . . . . ."
        ". ip12 ip12 . ip13 ip13 . . . . ip14 ip14"
        ". . . . . . ip15 ip15 . . ip14 ip14"
        ". . ip16 ip16 . . ip15 ip15 . . . ."
        ". . ip16 ip16 . . . . . . . ."
    }

    .games_box_3 {
      --gridTemplateColumns: 12;
      grid-template-areas:
        ". . . . . . . . . . . ."
        "topGame topGame topGame topGame . . . . . . . ."
        "topGame topGame topGame topGame . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . . rightAdv rightAdv rightAdv"
        ". . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . . ."
        ". . . . . . . . . . . ."
        ". . . . . newGame newGame newGame newGame . . ."
        ". . . . . newGame newGame newGame newGame . . ."
        ". . . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . . . ."
        ". . . . . . . . . . . ."
        ". . bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . . ."
    }
  }
}

@media (min-width: 1211px) and (max-width: 1320.9px) {
  .type-list, .middle_title {
    width: 1194px;
  }

  .content_box {
    width: 1194px;

    .games_box {
      --gridTemplateColumns: 11;
      grid-template-areas:
        ". . . . ip3 ip3 . . . ip4 ip4"
        "topGame topGame topGame topGame ip3 ip3 bigIp0 bigIp0 bigIp0 ip4 ip4"
        "topGame topGame topGame topGame . . bigIp0 bigIp0 bigIp0 . ."
        ". ip5 ip5 . . . bigIp0 bigIp0 bigIp0 . ."
        ". ip5 ip5 bigIp1 bigIp1 bigIp1 . . rightAdv rightAdv rightAdv"
        ". . . bigIp1 bigIp1 bigIp1 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 . bigIp1 bigIp1 bigIp1 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . ."
        ". . . . newGame newGame newGame newGame . ip8 ip8"
        ". . . . newGame newGame newGame newGame . ip8 ip8"
        ". . ip9 ip9 . . ip10 ip10 . . ."
        "ip11 ip11 ip9 ip9 . . ip10 ip10 . . ."
        "ip11 ip11 . . . bigIp2 bigIp2 bigIp2 . . ."
        ". . . ip12 ip12 bigIp2 bigIp2 bigIp2 . . ."
        ". ip13 ip13 ip12 ip12 bigIp2 bigIp2 bigIp2 . . ."
        ". ip13 ip13 . . . . . . . ."
        ". . . . . . . . . ip14 ip14"
        ". . . . . ip15 ip15 . . ip14 ip14"
        ". ip16 ip16 . . ip15 ip15 . . . ."
        ". ip16 ip16 . . . . . . . ."
    }
    .games_box_2 {
      --gridTemplateColumns: 11;
      grid-template-areas:
        ". . . . ip3 ip3 . . . ip4 ip4"
        "topGame topGame topGame topGame ip3 ip3 bigIp0 bigIp0 bigIp0 ip4 ip4"
        "topGame topGame topGame topGame . . bigIp0 bigIp0 bigIp0 . ."
        ". ip5 ip5 . . . bigIp0 bigIp0 bigIp0 . ."
        ". ip5 ip5 bigIp1 bigIp1 bigIp1 . . rightAdv rightAdv rightAdv"
        ". . . bigIp1 bigIp1 bigIp1 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 . bigIp1 bigIp1 bigIp1 ip6 ip6 rightAdv rightAdv rightAdv"
        "ip7 ip7 bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . ."
        ". . . . newGame newGame newGame newGame . ip8 ip8"
        ". . . . newGame newGame newGame newGame . ip8 ip8"
        ". . ip9 ip9 . . ip10 ip10 . . ."
        "ip11 ip11 ip9 ip9 . . ip10 ip10 rightAdv2 rightAdv2 rightAdv2"
        "ip11 ip11 . . . bigIp2 bigIp2 bigIp2 rightAdv2 rightAdv2 rightAdv2"
        ". ip12 ip12 ip13 ip13 bigIp2 bigIp2 bigIp2 rightAdv2 rightAdv2 rightAdv2"
        ". ip12 ip12 ip13 ip13 bigIp2 bigIp2 bigIp2 . . ."
        ". bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . ip14 ip14"
        ". . . . . ip15 ip15 . . ip14 ip14"
        ". ip16 ip16 . . ip15 ip15 . . . ."
        ". ip16 ip16 . . . . . . . ."
    }

    .games_box_3 {
      --gridTemplateColumns: 11;
      grid-template-areas:
        ". . . . . . . . . . ."
        "topGame topGame topGame topGame . . . . . . ."
        "topGame topGame topGame topGame . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . rightAdv rightAdv rightAdv"
        ". . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . ."
        ". . . . newGame newGame newGame newGame . . ."
        ". . . . newGame newGame newGame newGame . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . . . ."
        ". bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 . . ."
    }
  }
}

@media (min-width: 991px) and (max-width: 1210.9px) {
  .type-list, .middle_title {
    width: 980px;
  }

  .content_box {
    width: 980px;

    .games_box {
      --gridTemplateColumns: 9;
      grid-template-areas:
        ". . . . . . ip3 ip3 ."
        "topGame topGame topGame topGame ip4 ip4 ip3 ip3 ."
        "topGame topGame topGame topGame ip4 ip4 rightAdv rightAdv rightAdv"
        "ip5 ip5 . . . . rightAdv rightAdv rightAdv"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0 rightAdv rightAdv rightAdv"
        ". ip6 ip6 bigIp0 bigIp0 bigIp0 ip7 ip7 ."
        ". ip6 ip6 bigIp0 bigIp0 bigIp0 ip7 ip7 ."
        ". bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ."
        "bigIp1 bigIp1 bigIp1 . . . . . ."
        "bigIp1 bigIp1 bigIp1 newGame newGame newGame newGame ip8 ip8"
        "bigIp1 bigIp1 bigIp1 newGame newGame newGame newGame ip8 ip8"
        ". . bigIp2 bigIp2 bigIp2 . . . ."
        ". . bigIp2 bigIp2 bigIp2 . ip9 ip9 ."
        ". . bigIp2 bigIp2 bigIp2 . ip9 ip9 ."
        "ip10 ip10 . . . . . . ."
        "ip10 ip10 . ip11 ip11 . . . ."
        ". . . ip11 ip11 . . . ."
        ". ip12 ip12 . . ip13 ip13 . ."
        ". ip12 ip12 . . ip13 ip13 . ."
        ". . . ip14 ip14 . . . ."
        ". . . ip14 ip14 . . ip15 ip15"
        ". . . . . . . ip15 ip15"
        ". . ip16 ip16 . . . . ."
        ". . ip16 ip16 . . . . ."
    }

    .games_box_2 {
      --gridTemplateColumns: 9;
      grid-template-areas:
        ". . . . . . ip3 ip3 ."
        "topGame topGame topGame topGame ip4 ip4 ip3 ip3 ."
        "topGame topGame topGame topGame ip4 ip4 rightAdv rightAdv rightAdv"
        "ip5 ip5 . . . . rightAdv rightAdv rightAdv"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0 rightAdv rightAdv rightAdv"
        ". ip6 ip6 bigIp0 bigIp0 bigIp0 ip7 ip7 ."
        ". ip6 ip6 bigIp0 bigIp0 bigIp0 ip7 ip7 ."
        ". bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ."
        "bigIp1 bigIp1 bigIp1 . . . . ip8 ip8"
        "bigIp1 bigIp1 bigIp1 newGame newGame newGame newGame ip8 ip8"
        "bigIp1 bigIp1 bigIp1 newGame newGame newGame newGame . ."
        ". . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . bigIp2 bigIp2 bigIp2 . rightAdv2 rightAdv2 rightAdv2"
        ". . bigIp2 bigIp2 bigIp2 . ip9 ip9 ."
        ". . bigIp2 bigIp2 bigIp2 . ip9 ip9 ."
        ". bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 ."
        "ip10 ip10 . . . . . . ."
        "ip10 ip10 . ip11 ip11 . . . ."
        ". . . ip11 ip11 . . . ."
        ". ip12 ip12 . . ip13 ip13 . ."
        ". ip12 ip12 . . ip13 ip13 . ."
        ". . . ip14 ip14 . . . ."
        ". . . ip14 ip14 . . ip15 ip15"
        ". . . . . . . ip15 ip15"
        ". . ip16 ip16 . . . . ."
        ". . ip16 ip16 . . . . ."
    }

    .games_box_3 {
      --gridTemplateColumns: 9;
      grid-template-areas:
        ". . . . . . . . ."
        "topGame topGame topGame topGame . . . . ."
        "topGame topGame topGame topGame . . rightAdv rightAdv rightAdv"
        ". . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . rightAdv rightAdv rightAdv"
        ". . . . . . . . ."
        ". . . . . . . . ."
        ". bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ."
        ". . . . . . . . ."
        ". . . newGame newGame newGame newGame . ."
        ". . . newGame newGame newGame newGame . ."
        ". . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . . . ."
        ". . . . . . . . ."
        ". bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 ."
    }
  }
}

@media (min-width: 111px) and (max-width: 990.9px) {
  .type-list, .middle_title {
    width: 760px;
  }

  .content_box {
    width: 760px;

    .games_box {
      --gridTemplateColumns: 7;
      grid-template-areas:
        ". . . . bigIp0 bigIp0 bigIp0"
        "topGame topGame topGame topGame bigIp0 bigIp0 bigIp0"
        "topGame topGame topGame topGame bigIp0 bigIp0 bigIp0"
        ". . . . rightAdv rightAdv rightAdv"
        ". ip3 ip3 . rightAdv rightAdv rightAdv"
        ". ip3 ip3 . rightAdv rightAdv rightAdv"
        "bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv"
        ". . . . . . ."
        ". newGame newGame newGame newGame . ."
        ". newGame newGame newGame newGame . ."
        ". bigIp1 bigIp1 bigIp1 . . ."
        ". bigIp1 bigIp1 bigIp1 ip4 ip4 ."
        ". bigIp1 bigIp1 bigIp1 ip4 ip4 ."
        ". . ip5 ip5 . . ."
        ". . ip5 ip5 . . ."
        ". ip6 ip6 . . . ."
        ". ip6 ip6 bigIp2 bigIp2 bigIp2 ."
        ". . . bigIp2 bigIp2 bigIp2 ."
        ". . . bigIp2 bigIp2 bigIp2 ."
        "ip7 ip7 . . . ip8 ip8"
        "ip7 ip7 . . . ip8 ip8"
        ". ip9 ip9 . . . ."
        ". ip9 ip9 . . . ."
        ". . ip10 ip10 ip11 ip11 ."
        ". . ip10 ip10 ip11 ip11 ."
        "ip12 ip12 . . . . ."
        "ip12 ip12 . . ip13 ip13 ."
        ". ip14 ip14 . ip13 ip13 ."
        ". ip14 ip14 ip15 ip15 . ."
        ". . . ip15 ip15 . ."
        ". . . . . . ."
        ". . . ip16 ip16 . ."
        ". . . ip16 ip16 . ."
    }
    .games_box_2 {
      --gridTemplateColumns: 7;
      grid-template-areas:
        ". . . . bigIp0 bigIp0 bigIp0"
        "topGame topGame topGame topGame bigIp0 bigIp0 bigIp0"
        "topGame topGame topGame topGame bigIp0 bigIp0 bigIp0"
        ". . . . rightAdv rightAdv rightAdv"
        ". ip3 ip3 . rightAdv rightAdv rightAdv"
        ". ip3 ip3 . rightAdv rightAdv rightAdv"
        "bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv"
        ". . . . . . ."
        ". newGame newGame newGame newGame . ."
        ". newGame newGame newGame newGame . ."
        ". bigIp1 bigIp1 bigIp1 . . ."
        ". bigIp1 bigIp1 bigIp1 ip4 ip4 ."
        ". bigIp1 bigIp1 bigIp1 ip4 ip4 ."
        ". . ip5 ip5 rightAdv2 rightAdv2 rightAdv2"
        ". . ip5 ip5 rightAdv2 rightAdv2 rightAdv2"
        ". ip6 ip6 . rightAdv2 rightAdv2 rightAdv2"
        ". ip6 ip6 . . . ."
        "bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2"
        "ip7 ip7 . . . ip8 ip8"
        "ip7 ip7 bigIp2 bigIp2 bigIp2 ip8 ip8"
        "ip9 ip9 bigIp2 bigIp2 bigIp2 . ."
        "ip9 ip9 bigIp2 bigIp2 bigIp2 . ."
        ". . ip10 ip10 ip11 ip11 ."
        ". . ip10 ip10 ip11 ip11 ."
        "ip12 ip12 . . . . ."
        "ip12 ip12 . . ip13 ip13 ."
        ". ip14 ip14 . ip13 ip13 ."
        ". ip14 ip14 ip15 ip15 . ."
        ". . . ip15 ip15 . ."
        ". . . . . . ."
        ". . . ip16 ip16 . ."
        ". . . ip16 ip16 . ."
    }

    .games_box_3 {
      --gridTemplateColumns: 7;
      grid-template-areas:
        ". . . . . . ."
        "topGame topGame topGame topGame . . ."
        "topGame topGame topGame topGame . . ."
        ". . . . rightAdv rightAdv rightAdv"
        ". . . . rightAdv rightAdv rightAdv"
        ". . . . rightAdv rightAdv rightAdv"
        "bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv"
        ". . . . . . ."
        ". newGame newGame newGame newGame . ."
        ". newGame newGame newGame newGame . ."
        ". . . . . . ."
        ". . . . . . ."
        ". . . . . . ."
        ". . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . rightAdv2 rightAdv2 rightAdv2"
        ". . . . . . ."
        "bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2 bottomAdv2"
    }
  }
}

.big-box {
  display: flex;
  flex-direction: column;
  .content_box {
    margin: 0px auto;
    flex-grow: 1;

    .games_box,.games_box_2,.games_box_3 {
      display: grid;
      grid-template-rows: repeat(auto-fill, 94px);
      grid-gap: 16px;
      grid-auto-flow: dense;
      justify-content: center;
      margin: 16px auto 0px;
      padding: 0px;
      list-style-type: none;
      //--gridTemplateColumns: 3;
      grid-template-columns: repeat(var(--gridTemplateColumns), 94px);

      .big_img_box {
        display: contents;

        li {
          display: contents;

          a {
            text-decoration: none;
            color: #009cff;
          }
        }
      }
      .rightAdv, .bottomAdv{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .adv_box {
          display: inline-flex;
          flex-direction: column;
          .adv_box_1 {
            background: rgba(255, 255, 255, 0.5);
          }

          .iZJgLq {
            font: 400 9px / 2 Arial, sans-serif;
            //text-transform: uppercase;
            text-align: center;
            opacity: 0.7;
            height: 20px;
            letter-spacing: 1px;
            color: #002b50;
          }
        }
      }
      .rightAdv{
        height: 314px;
      }
      .bottomAdv{
        height: 94px;
      }
    }
    .top_game{
      border-radius: 12px;
      background: linear-gradient(180deg,rgb(255, 225, 210),#fff 28%);
      box-shadow: 0 4px 10px 0 rgba(0,0,0,.2);
      .top_box{
        margin: 10px 16px 10px;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        img{
          vertical-align: middle;
          width: 30px;
          height: 30px;
        }
        .title{
          position: absolute;
          top: 1px;
          left: 39px;
          z-index: 1;
        }
        .title_bj{
          display: block;
          width: 105px;
          height: 10px;
          position: absolute;
          left: 39px;
          bottom: 3px;
          background: linear-gradient(90deg,#ffcc19,rgba(255,204,25,0));
          z-index: 0;
        }
      }
      .top_game_list{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        padding: 0 10px;
        a{
          text-decoration: none;
          color: #000;
          width: 120px;
          height: 135px;
          position: relative;
          padding: 4px;
          border-radius: 16px;
          background: #fff;
          box-shadow: 0 4px 10px 0 rgba(0,0,0,.2);
          transform: scale(1) translateY(-4px);
          transition-duration: .3s;
          cursor: pointer;
        }
        a:nth-child(1n) {
          background: linear-gradient(to bottom right,#ecfcff,#ecfcff);
          .bj_1{
            background: #32e7ff;
          }
          .bj_2{
            background: #32e7ff;
          }
        }
        a:nth-child(2n) {
          background: linear-gradient(to bottom right,#fdf2f7,#fbf2f8);
          .bj_1{
            background: #ff73a9;
          }
          .bj_2{
            background: #ff73a9;
          }
        }
        a:nth-child(3n) {
          background: linear-gradient(to bottom right,#fbfbf1,#fdfbec);
          .bj_1{
            background: #ffda1a;
          }
          .bj_2{
            background: #ffda1a;
          }
        }
        a:hover {
          transform: scale(1.05) translateY(-2.05%);
          transition-duration: .3s;
        }
        p{
          width: 100%;
          height: 110px;
        }
        img{
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
        h3{
          line-height: 30px;
          white-space: nowrap;
          font-size: 12px;
          text-align: center;
          opacity: .8;
        }
        .bj_1{
          width: calc(100% - 12px);
          height: 110px;
          position: absolute;
          top: 8px;
          left: 6px;
          border-radius: 12px;
          opacity: .5;
          z-index: -1;
        }
        .bj_2{
          width: calc(100% - 16px);
          height: 110px;
          position: absolute;
          top: 12px;
          left: 8px;
          border-radius: 12px;
          opacity: .2;
          z-index: -1;
        }
      }
    }

    .games_box::before {
      content: "";
      display: block;
    }
    .games_box_2::before {
      content: "";
      display: block;
    }
    .games_box_3::before {
      content: "";
      display: block;
    }
  }

  .img_style {
    display: block;
    --minSize: 94px;
    min-width: var(--minSize);
    min-height: var(--minSize);
    border-radius: inherit;
    aspect-ratio: 1 / 1;
  }

  .a_style_2 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    user-select: none;
    aspect-ratio: 1 / 1;
    cursor: pointer;
  }

  .a_style_1 {
    display: block;
    transition: transform .6s cubic-bezier(.25, .1, .25, 1);
    border-radius: 16px;
    position: relative;
  }

  .a_style_1:hover {
    transform: scale(1.04255) translate(0px, -4px);
    transition-duration: 0.3s;
  }

  .a_style_1::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 4;
    transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1), opacity .3s cubic-bezier(.25, .1, .25, 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    contain: strict;
  }

  .position {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    color: rgb(255, 255, 255);
    font: 700 var(--tileFontSize,14px)/1.3 'Proxima Nova', sans-serif;
    text-align: center;
    z-index: 6;
    transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s, opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
    transform: translate(0px, 8px);
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    word-break: break-all;
  }
  .hot_box {
    position: absolute;
    z-index: 5;
    left: -6px;
    right: 0px;
    height: 28px;
    top: 8px;
    font: 700 11px/28px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
    color: #009cff;
    text-transform: uppercase;
    padding: 0px 8px 0px 6px;
    pointer-events: none;
    .hot_img {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      background: rgb(236, 27, 37);
      box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
      border-radius: 3px 14px 14px 0px;
      //padding: 0px 8px 0px 3px;
      img{
        width: 39px;
        height: 39px;
        //padding: 5px 0px 0px 3px;
        z-index: 2;
        box-sizing: border-box;
      }
    }
  }
  .hot_box:before {
    background: rgb(236, 27, 37);
    bottom: -4px;
    height: 4px;
    width: 4px;
    z-index: 0;
    content: "";
    left: 0;
    position: absolute;
  }
  .hot_box:after {
    background: #bac9de;
    border-radius: 4px 0 0 4px;
    bottom: -8px;
    height: 8px;
    width: 6px;
    z-index: 1;
    content: "";
    left: 0;
    position: absolute;
  }
  .flash_box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
  }
  .cYmAAh {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate3d(-50%, 0%, 0px);
    will-change: transform, opacity;
    pointer-events: none;
    opacity: 0;
    z-index: 3;
    animation: 6s linear 6s infinite normal none running jygSil;
    width: 200%;
    height: 200%;
  }
  .video_box {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    visibility: hidden;
    z-index: 5;

    .video_style {
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translate(-50%, 0px);
    }
  }
  .middle_title{
    font: 500 24px Torus, sans-serif;
    margin: 16px auto 0;
  }
  .type-list {
    margin: 16px auto 0;

    .bottom-text {
      position: relative;
      margin: 30px 0 0;
      padding: 18px 24px;
      background: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;

      h1{
        font: 500 24px Torus,sans-serif;
      }
      p{
        margin: 24px 0;
        font: 400 16px/24px Proxima Nova,Open Sans,Gill Sans MT,Gill Sans,Arial,sans-serif;
        color: #002b50;
      }
      a{
        color: #054a91;
        text-decoration: underline;
      }
      h3{
        color: #002b50;
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
      ul{
        padding-left: 40px;
        li{
          margin-bottom: 5px;
        }
      }
      .about{
        color: #5d6b84;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
        margin: 3px 0 0;
        text-transform: uppercase;
      }
      .title{
        font-size: 20px;
        color: #002b50;
      }
    }
  }
}
@keyframes jygSil {
  0% {
    transform: translate3d(-55%, -5%, 0px);
    opacity: 0;
  }
  1% {
    transform: translate3d(-50%, 0%, 0px);
    opacity: 1;
  }

  10% {
    transform: translate3d(0%, -50%, 0px);
    opacity: 1;
  }
  15% {
    transform: translate3d(0%, -50%, 0px);
    opacity: 0;
  }
  39.99% {
    transform: translate3d(-55%, -5%, 0px);
    opacity: 0;
  }
  40% {
    transform: translate3d(-50%, 0%, 0px);
    opacity: 1;
  }
  50% {
    transform: translate3d(0%, -50%, 0px);
    opacity: 1;
  }
  66% {
    transform: translate3d(0%, -50%, 0px);
    opacity: 0;
  }
  100% {
    transform: translate3d(0%, -50%, 0px);
    opacity: 0;
  }
}
@media (hover: hover) {
  .a_style_2:hover {
    transform: scale(1.01869) translate(0px, -4px) !important;
  }
  .a_style_2:hover::after {
    opacity: 1;
    background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
  }
  .a_style_2:hover {
    .opacity {
      opacity: 1;
      transform: translate(0px, 0px);
    }

    .visible {
      visibility: visible;
    }
  }
}
</style>
