<template>
  <div>
    <div class="logo" :style="qagameStyle ? {position: 'relative',top: 0, left: 0, transform: 'translate(0)'} : {}">
      <div class="home_style" :style="$originCopyWriting == 'makefun' || $originCopyWriting == 'littlefun' || $originCopyWriting == 'minifun' || $originCopyWriting == 'plusfun' || $originCopyWriting == 'afuntime' || $originCopyWriting == 'getfun' || logoStyle ? {margin: '10px auto 20px'} : null">
        <a :href="jumpSubdomain && whereFrom == 2 ? (newJumpUrl + '/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')) : ('/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): ''))" @click="goHome">
          <img :src="logo" alt=""
               :style="$originCopyWriting == 'makefun' || $originCopyWriting == 'littlefun' || $originCopyWriting == 'minifun' || $originCopyWriting == 'plusfun' || $originCopyWriting == 'afuntime' || $originCopyWriting == 'getfun' || logoStyle ? {width: '70px',height: '40px'} : null">
<!--          {{ this.$originCopyWriting }}-->
        </a>
      </div>
      <div class="home_box">
        <a :href="jumpSubdomain && whereFrom == 2 ? (newJumpUrl + '/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')) : ('/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): ''))" class="home_style" @click="goHome"><img :src="home" alt=""></a>
        <button class="sousuo_style sousuo_style_1" @click="searchClick">
          <img :src="souSuo" alt="">
        </button>
      </div>
    </div>
    <SearchFor :UnfoldAndCollapse="UnfoldAndCollapse" v-if="UnfoldAndCollapse" :whereFrom="whereFrom" :portal="portal" :gameName="gameName"  @searchClick="searchClick"/>
  </div>
</template>

<script>
import logo from '/public/img/logos/defaultLogo.png'
import home from '@/assets/home.png'
import souSuo from '@/assets/sousuo.png'

import SearchFor from '@/components/SearchFor.vue';
import {clickSearchLog, pageOutLog} from "@/utils/utils";
export default {
  name: "MobileLogo",
  props: ["whereFrom","portal","gameName","qagameStyle"], // whereFrom 1首页 2详情页 3类型页  qagameStyle:qagame的样式
  components: {
    SearchFor
  },
  data() {
    return {
      logo: this.$originCopyWriting == 'hahamini' ? `${window.location.origin}/img/logos/hahaminiLogo.png` :
          this.$originCopyWriting == 'kkplay' ? `${window.location.origin}/img/logos/kkplayLogo.png` :
              this.$originCopyWriting == 'playus' ? `${window.location.origin}/img/logos/playusLogo.png` :
                  this.$originCopyWriting == 'oppogame' ? `${window.location.origin}/img/logos/oppogameLogo.png` :
                      this.$originCopyWriting == 'h5joy' ? `${window.location.origin}/img/logos/h5joyLogo.png` :
                          this.$originCopyWriting == 'joymarvel' ? `${window.location.origin}/img/logos/joymarvelLogo.png` :
                              this.$originCopyWriting == 'gamesinall' ? `${window.location.origin}/img/logos/gamesinallLogo.png` :
                                  this.$originCopyWriting == 'ajoy' ? logo :
                                      this.$originCopyWriting == 'agame' ? logo :
                                          this.$originCopyWriting == 'zzboom' ? logo :
                                              this.$originCopyWriting == 'gdgame' ? logo :
                                                  this.$originCopyWriting == 'qagame' ? logo :
                                                      this.$originCopyWriting == 'yamat' ? logo :
                                                      `${window.location.origin}/img/logos/makefunLogo.png`,
      home,souSuo,
      UnfoldAndCollapse: false, // 展开收起
      logoStyle: false, // logo样式
      newJumpUrl: null,
      jumpSubdomain: null,
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { logoStyle, jumpSubdomain } = channelInfo || {}
    this.logoStyle = logoStyle
    this.jumpSubdomain = jumpSubdomain
    this.newJumpUrl = window.location.protocol + '//' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  methods: {
    goHome() {
      if (this.whereFrom == 2){
        pageOutLog(this.portal)
        // detailsPageOutLog(this.portal, this.gameName)
      } else {
        // 离开页面埋点
        pageOutLog(this.portal)
      }
    },
    // 点击搜索
    searchClick() {
      this.UnfoldAndCollapse = !this.UnfoldAndCollapse
      // 点击搜索打点
      let portal = this.whereFrom == 1 ? 'mobile_home' : this.whereFrom == 2 ? 'mobile_detail' : 'mobile_tab'
      clickSearchLog(portal)
    },
  }
}
</script>

<style lang="less" scoped>
a{
  text-decoration: none;
  color: #000000;
}
@media (min-width: 551px) and (max-width: 660.9px) {
  .logo {
    --offset: -268px !important;
  }
}
@media (min-width: 661px) and (max-width: 880.9px) {
  .logo {
    --offset: -322px !important;
  }
}
@media (min-width: 881px) {
  .logo {
    --offset: -432px !important;
  }
}
.logo{
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 16px;
  background: rgb(255, 255, 255);
  box-shadow: 0 6px 32px 0 rgba(0, 0, 0, .24);
  border-radius: 16px;
  width: 94px;
  height: 94px;
  flex-direction: column;
  --left: 50%;
  left: var(--left);
  transform: translate(var(--offset),0);
  --offset: -157px;
  .home_style{
    //width: 80px;
    height: 30px;
    margin: 15px auto 11px;
    font: 700 13px "SHOWG", sans-serif;
    line-height: 28px;
    text-align: center;
    a{
      display: block;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .home_style:hover{
    //transform: scale(1.05);
  }
  .home_box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #f0f5fc;
    width: 100%;
    height: 40px;
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
    .home_style {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      border-right: 1px solid #f0f5fc;
    }
    .home_style:hover{
      background: #f0f5fc;
    }
    .sousuo_style_1{
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      border-left: 1px solid #f0f5fc;
      text-indent: -200vw;
      font-size: 0px;
      background: white;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .sousuo_style_1:hover{
      background: #f0f5fc;
    }
    .sousuo_style {
      font-size: 100%;
      font-family: inherit;
      border: 0px;
      padding: 0px;
      background: none;
      cursor: pointer;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
}
</style>