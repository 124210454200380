import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import h5joyRouter from './router/h5joy'
import store from './store'
import axios from 'axios'
import '@/assets/font/theFont.css'
import { Button, Select, Input, Message, Form, FormItem, Divider } from 'element-ui'
import dayjs from "dayjs"

// 域名
let originCopyWriting = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(-2)[0] : 'getfun'
let headToUpperCase = originCopyWriting.slice(0,1).toUpperCase() +originCopyWriting.slice(1).toLowerCase()
let suffix = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(-2)[1] : 'online'
Vue.prototype.$originCopyWriting = originCopyWriting
Vue.prototype.$headToUpperCase = headToUpperCase
Vue.prototype.$suffix = suffix
Vue.prototype.$axios = axios
Vue.prototype.$message = Message
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs;

// 引入插件
import VueLazyload from 'vue-lazyload'
import {clickInstallLog, determinePcOrMove, followShortcutsLog, getUrlParams, pageInitLog, clickHomeInstallLog, clickDetailsInstallLog} from "@/utils/utils";
import h5joy from "./router/h5joy";

// 注册插件
Vue.use(VueLazyload,{
  loading:'' // 懒加载默认图片
})


window.addEventListener('beforeinstallprompt', (e) => {
  // 防止 Chrome 67 及更早版本自动显示安装提示
  e.preventDefault();
  console.log(e);
  /**直接触发浏览器安装保存数据通过监听appinstalled事件删除数据*/
  window.deferredPrompt = e;
  /**通过页面按钮触发安装*/
  // 稍后再触发此事件
  store.commit("changePWA",{deferredPrompt: e,deferredPromptType: true})
  // 用户手动触发
  setTimeout(()=>{
    store.state.deferredPrompt.userChoice.then((choiceResult) => {
      console.log(choiceResult.outcome);
      if (choiceResult.outcome === 'dismissed') {
        console.log('用户取消了安装');
        localStorage.setItem('installType','0')
        store.commit("changeInstallType",'0')
        store.commit("changeInstallPageClick",null)
        localStorage.setItem('installCancelTime',dayjs().format('YYYY-MM-DD'))
      }
      else {
        console.log('用户已安装程序');
        if (store.state.installPageClick == 1) {
          clickHomeInstallLog('pc_home')
        } else if (store.state.installPageClick == 2) {
          clickDetailsInstallLog('pc_detail')
        } else if (store.state.installPageClick == 3) {
          clickHomeInstallLog('mobile_home')
        }
        store.commit("changePWA",{deferredPrompt: null,deferredPromptType: false})
        store.commit("changeInstallPageClick",null)
      }
    });
  })
});



// 浏览器监听pwa点击安装后事件
window.addEventListener('appinstalled',()=>{
  let page = null
  if (window.location.href.indexOf('/homeIndex/') > -1) {
    page = 'home'
  } else if (window.location.href.indexOf('/details/') > -1) {
    page = 'detail'
  } else if (window.location.href.indexOf('/gameType/') > -1) {
    page = 'tab'
  } else {
    page = 'home'
  }
  if (store.state.installPageClick) {

  } else {
    if (determinePcOrMove() == 2) {
      clickInstallLog(`pc_${page}`)
    } else {
      clickInstallLog(`mobile_${page}`)
    }
  }
  window.deferredPrompt = null;
  store.commit("changePWA",{deferredPrompt: null,deferredPromptType: false})
})

// 判断是通过网页启动还是通过主屏幕图标启动
if(window.location.href.match('pwa=client')){
  if (determinePcOrMove() == 2) {
    followShortcutsLog('pc_home')
  } else {
    followShortcutsLog('mobile_home')
  }
}


// 监听横屏竖屏切换
window.addEventListener('orientationchange', function() {
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    setTimeout(()=>{
      //fontsize计算
      document.documentElement.style.fontSize = document.documentElement.clientWidth/320 * 16 +'px'
    },100)
  }
});

// 修改title
Vue.directive('title', {
  // 单个修改标题
  update: function (el) {
    console.log(el.dataset.title);
    if (el.dataset.title) {
      setTimeout(()=>{
        document.title = el.dataset.title
      })
    }
  }
})

let timer = setInterval(()=>{
  if (window.loadOk) {
    clearInterval(timer)
    // 默认常玩游戏
    let arr = getAllJson() || [] // 原数组
    let recentGame = []
    if (!localStorage.getItem('recentGame')) {
      arr.map((item)=>{
        if (recentGame.length < 6) {
          item.filterStatus = 0 // 筛选状态用来判断点击游戏时替换数组中的位置元素
          recentGame.push(item)
        }
      })
      localStorage.setItem('recentGame',JSON.stringify(recentGame))
    }
  }
},2000)

// 清除浏览器 localStorage 缓存(只在重新打开页面时起作用)
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem('appVersion')
if(VUE_APP_VERSION != vers){
  localStorage.clear()
  window.localStorage.setItem('appVersion', VUE_APP_VERSION)
  location.reload()
}

// 解决地址栏参数在/#前面问题
let b = new URL(window.location.href);
console.log(b);
if (b.pathname === '/' || b.hash === '#/') {
  if (determinePcOrMove() == 2) {
    if (b.search) {
      history.replaceState(null,null,`${b.origin + b.pathname}${originCopyWriting == 'h5joy' || originCopyWriting == 'hahamini' || originCopyWriting == 'kkplay' ? 'Pc' : 'P'}/homeIndex${b.search}`)
      console.log(window.location.href);
    } else if (getUrlParams('channel')){
      history.replaceState(null,null,`${b.origin + b.pathname}${originCopyWriting == 'h5joy' || originCopyWriting == 'hahamini' || originCopyWriting == 'kkplay' ? 'Pc' : 'P'}/homeIndex?channel=${getUrlParams('channel')}`)
    } else {
      history.replaceState(null,null,`${b.origin + b.pathname}${originCopyWriting == 'h5joy' || originCopyWriting == 'hahamini' || originCopyWriting == 'kkplay' ? 'Pc' : 'P'}/homeIndex`)
      console.log(window.location.href);
    }
  } else {
    if (b.search) {
      history.replaceState(null,null,`${b.origin + b.pathname}M/homeIndex${b.search}`)
    } else if (getUrlParams('channel')){
      history.replaceState(null,null,`${b.origin + b.pathname}M/homeIndex?channel=${getUrlParams('channel')}`)
    } else {
      history.replaceState(null,null,`${b.origin + b.pathname}M/homeIndex`)
    }
  }
}

// 动态修改pwa
let myDynamicManifest = {
  "short_name": `${originCopyWriting}.${suffix}`,
  "name": `${originCopyWriting}.${suffix}`,
  "icons": [
    {
      "src": `${window.location.origin}/img/pwa.png`,
      "sizes": "192x192",
      "type": "image/png"
    },
    {
      "src": `${window.location.origin}/img/pwa.png`,
      "sizes": "512x512",
      "type": "image/png"
    }
  ],
  "start_url": getUrlParams('channel') ? `${window.location.origin}?pwa=client&channel=${getUrlParams('channel')}` : `${window.location.origin}?pwa=client`,
  "display": "standalone",
  "background_color": "#0054ff",
  "theme_color": "#080403",
  "description": `${headToUpperCase} is an online gaming website where you will find various types of games`,
  "screenshots": [
    {
      "src": `${window.location.origin}/img/install/1920x1080-1.webp`,
      "sizes": "1920x1080",
      "type": "image/webp",
      "form_factor": "wide"
    },
    {
      "src": `${window.location.origin}/img/install/1920x1080-2.webp`,
      "sizes": "1920x1080",
      "type": "image/webp",
      "form_factor": "wide"
    },
    {
      "src": `${window.location.origin}/img/install/1920x1080-3.webp`,
      "sizes": "1920x1080",
      "type": "image/webp",
      "form_factor": "wide"
    },
    {
      "src": `${window.location.origin}/img/install/1920x1080-4.webp`,
      "sizes": "1920x1080",
      "type": "image/webp",
      "form_factor": "wide"
    },
    {
      "src": `${window.location.origin}/img/install/1920x1080-5.webp`,
      "sizes": "1920x1080",
      "type": "image/webp",
      "form_factor": "wide"
    },
    {
      "src": `${window.location.origin}/img/install/1920x1080-6.webp`,
      "sizes": "1920x1080",
      "type": "image/webp",
      "form_factor": "wide"
    }
  ]
}
const stringManifest = JSON.stringify(myDynamicManifest);
const blob = new Blob([stringManifest], {type: 'application/json'});
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#manifest').setAttribute('href', manifestURL)


// 监听点击广告离开页面
let LeavingThePage = false
document.addEventListener("visibilitychange", function() {
  if (document.hidden) {
    // 当前标签页被切换到后台
    setTimeout(()=>{
      console.log(LeavingThePage);
      if (LeavingThePage == true) {
        console.log("标签页被切换到后台");
        if (originCopyWriting == 'ajoy') {
          bge && bge('event', 'ec_add_cart', {configId: '903985143796275456'});
        }
        if(typeof(dataLayer) != 'undefined'){
          // 点击首页广告
          if (window.location.href.indexOf('/homeIndex') > -1) {
            dataLayer.push({'event':'click_home_ad'});
          }
        }
        // ttq && ttq.instance('CISK3BRC77UF1TU2ERL0').track('AddToWishlist');
        // 点击穿插广告
        if (window.location.href.split('#')[1] == 'google_vignette') {
          let insertAdNum = localStorage.getItem('insertAdClick')
          localStorage.setItem('insertAdClick',insertAdNum ? String(Number(insertAdNum) + 1) : '1')
          localStorage.setItem('insertAdClickTime',dayjs().format('YYYY-MM-DD'))
          if(typeof(dataLayer) != 'undefined'){
            dataLayer.push({'event':'user_click_vignette','vignette_clicked_num':localStorage.getItem('insertAdClick')});
          }
          if (originCopyWriting == 'plusfun') {
            if (localStorage.getItem('insertAdClick') == 1) {
              ttq && ttq.instance('CO9TDDBC77U1MKJ2MPLG').track('Subscribe')
            }
          }
          if (originCopyWriting == 'mobaplay') {
            if (localStorage.getItem('insertAdClick') == 1) {
              ttq && ttq.instance('COBQCLRC77UFSP5UUVI0').track('Subscribe')
            }
          }
          // if (originCopyWriting == 'makefun') {
          //   if (localStorage.getItem('insertAdClick') == 1) {
          //     ttq && ttq.instance('CMFOJTJC77U16065M4LG').track('Subscribe')
          //     ttq && ttq.instance('CNRR5E3C77UCJOSK01FG').track('Subscribe')
          //   }
          //   if (localStorage.getItem('insertAdClick') == 2) {
          //     ttq && ttq.instance('CMFOJTJC77U16065M4LG').track('Search')
          //     ttq && ttq.instance('CNRR5E3C77UCJOSK01FG').track('Search')
          //   }
          //   if (localStorage.getItem('insertAdClick') == 3) {
          //     ttq && ttq.instance('CMFOJTJC77U16065M4LG').track('Download')
          //   }
          // }
        }
        if (window.location.href.indexOf('/homeIndex') > -1 && originCopyWriting == 'gdgame') {
          ttq && ttq.track('AddPaymentInfo')
        }
        if (originCopyWriting == 'gdgame') {
          ttq && ttq.track('AddToWishlist');
        }
        // 点击任意广告
        let arbitrarilyAdv = localStorage.getItem('arbitrarilyAdvClick')
        localStorage.setItem('arbitrarilyAdvClick',arbitrarilyAdv ? String(Number(arbitrarilyAdv) + 1) : '1')
        localStorage.setItem('arbitrarilyAdvClickTime',dayjs().format('YYYY-MM-DD'))
        if(typeof(dataLayer) != 'undefined'){
          dataLayer.push({'event':'user_click_ad','ad_clicked_num':localStorage.getItem('arbitrarilyAdvClick')});
        }
        if (originCopyWriting == 'plusfun') {
          // if (localStorage.getItem('arbitrarilyAdvClick') == 1) {
          //   ttq && ttq.instance('CO9TDDBC77U1MKJ2MPLG').track('Search')
          // }
          ttq && ttq.instance('CO9TDDBC77U1MKJ2MPLG').track('Search')
        }
        if (originCopyWriting == 'mobaplay') {
          // if (localStorage.getItem('arbitrarilyAdvClick') == 1) {
          //   ttq && ttq.instance('COBQCLRC77UFSP5UUVI0').track('Search')
          // }
          ttq && ttq.instance('COBQCLRC77UFSP5UUVI0').track('Search')
        }
        // if (originCopyWriting == 'makefun') {
        //   if (localStorage.getItem('arbitrarilyAdvClick') >= 3) {
        //     if (localStorage.getItem('arbitrarilyAdvClickType') != 1) {
        //       ttq && ttq.instance('CMFOJTJC77U16065M4LG').track('ClickButton')
        //       ttq && ttq.instance('CNRR5E3C77UCJOSK01FG').track('ClickButton')
        //       localStorage.setItem('arbitrarilyAdvClickType', '1')
        //     }
        //   }
        //   // 时长>=5分钟，任意广告点击>=1次
        //   let enterTimeLength = Number(localStorage.getItem('enterTime')) ? (new Date().getTime() - Number(localStorage.getItem('enterTime')) + Number(localStorage.getItem('enterTimeLength') ? localStorage.getItem('enterTimeLength') : 0)) : 0
        //   localStorage.setItem('enterTimeLength',enterTimeLength.toString())
        //   if (enterTimeLength >= 300000 && localStorage.getItem('enterIconType4') != 1) {
        //     ttq && ttq.instance('CMFOR4BC77U66B410M50').track('Search')
        //     ttq && ttq.instance('CNRR91JC77U7RLRSBSSG').track('Search')
        //     localStorage.setItem('enterIconType4','1')
        //   }
        // }
        LeavingThePage = false
      }
    },300)

    if (window.location.href.indexOf('/details/') > -1) {
      console.log(1111111);
      let timeLength = Number(localStorage.getItem('timeOn')) ? (new Date().getTime() - Number(localStorage.getItem('timeOn')) + Number(localStorage.getItem('timeLength') ? localStorage.getItem('timeLength') : 0)) : 0
      localStorage.setItem('timeLength',timeLength.toString())
      store.commit('changePageHiddenTypeClick',true)
    }
    // if (originCopyWriting == 'makefun') {
    //   // 用户游戏时长10min
    //   let enterTimeLength = Number(localStorage.getItem('enterTime')) ? (new Date().getTime() - Number(localStorage.getItem('enterTime')) + Number(localStorage.getItem('enterTimeLength') ? localStorage.getItem('enterTimeLength') : 0)) : 0
    //   localStorage.setItem('enterTimeLength',enterTimeLength.toString())
    //   let enterTime = Number(localStorage.getItem('enterTime')) ? new Date().getTime() : 0
    //   localStorage.setItem('enterTime',enterTime.toString())
    //   if (enterTimeLength >= 600000 && localStorage.getItem('enterType') != 1) {
    //     // ttq && ttq.instance('CMFOR4BC77U66B410M50').track('ClickButton')
    //     localStorage.setItem('enterType','1')
    //   }
    // }
  } else {
    // 当前标签页被切换到前台
    console.log("标签页被切换到前台");

    window.focus()

    if (window.location.href.indexOf('/details/') > -1) {
      console.log(22222);
      let timeOn = Number(localStorage.getItem('timeOn')) ? new Date().getTime() : 0
      localStorage.setItem('timeOn',timeOn.toString())
      store.commit('changePageHiddenTypeClick',false)
    }
    // if (originCopyWriting == 'makefun') {
    //   let enterTime = Number(localStorage.getItem('enterTime')) ? new Date().getTime() : 0
    //   localStorage.setItem('enterTime',enterTime.toString())
    // }
  }
});
window.addEventListener('blur',()=>{
  setTimeout(()=>{
    if (document.activeElement.tagName==='IFRAME'){
      LeavingThePage = true
      setTimeout(()=>{
        LeavingThePage = false
        // window.blur()
        // window.focus()
      },1200)
    }else {
      LeavingThePage = false
    }
  })
})

// 初始进入页面上报
if (!sessionStorage.getItem('pixelType') && originCopyWriting == 'ajoy') {
  bge && bge('event', 'consult', {configId: '903985143796275456'});
  sessionStorage.setItem('pixelType','1')
}

// 点击游戏图标次数
if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('iconClickTime')))) {
  localStorage.setItem('iconClick','0')
  localStorage.setItem('iconClickTime',dayjs().format('YYYY-MM-DD'))
}
// 点击广告次数
if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('arbitrarilyAdvClickTime')))) {
  localStorage.setItem('arbitrarilyAdvClick','0')
  localStorage.setItem('arbitrarilyAdvClickType','0')
  localStorage.setItem('arbitrarilyAdvClickTime',dayjs().format('YYYY-MM-DD'))
}
// 点击穿插广告次数
if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('insertAdClickTime')))) {
  localStorage.setItem('insertAdClick','0')
  localStorage.setItem('insertAdClickTime',dayjs().format('YYYY-MM-DD'))
}
// if (originCopyWriting == 'makefun') {
//   // 进入页面时间
//   if (localStorage.getItem('enterTime')) {
//     if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('enterTimeClickTime')))) {
//       let enterTime = new Date().getTime()
//       localStorage.setItem('enterTime',enterTime.toString())
//       localStorage.setItem('enterTimeClickTime',dayjs().format('YYYY-MM-DD'))
//       localStorage.setItem('enterType','0')
//       localStorage.setItem('enterIconType','0')
//       localStorage.setItem('enterIconType3','0')
//       localStorage.setItem('enterIconType4','0')
//       localStorage.setItem('enterTimeLength','0')
//     }
//   } else {
//     let enterTime = new Date().getTime()
//     localStorage.setItem('enterTime',enterTime.toString())
//     localStorage.setItem('enterTimeClickTime',dayjs().format('YYYY-MM-DD'))
//     localStorage.setItem('enterType','0')
//     localStorage.setItem('enterIconType','0')
//     localStorage.setItem('enterTimeLength','0')
//     localStorage.setItem('enterIconType3','0')
//     localStorage.setItem('enterIconType4','0')
//   }
// }

Vue.use(Button)
Vue.use(Select)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Divider)
new Vue({
  router: originCopyWriting == 'h5joy' || originCopyWriting == 'hahamini' || originCopyWriting == 'kkplay' ? h5joyRouter: router,
  store,
  render: h => h(App)
}).$mount('#app')
