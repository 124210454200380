<template>
  <div v-title :data-title="404">
    <div v-if="determinePcOrMove == 2">
      <div class="content">
        <div class="msg-404">
          <h4 class="msg-title">404</h4>
          <p class="not-found">Not Found</p>
          <p class="msg-text">Sorry, the page you requested does not exist on this site</p>
        </div>
        <div class="popular-games">
          <h6 class="title">The Most Popular Games</h6>
          <div class="games">
            <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" :class="styleAdjust ? 'item item-1' : 'item'" @click="iconClick(item)" v-for="(item,index) in gameList" :key="index">
              <img v-lazy="item.iconUrl" alt="">
              <span class="opacity position">{{item.Name}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="m-content">
        <div class="msg-404">
          <h4 class="msg-title">404</h4>
          <p class="not-found">Not Found</p>
          <p class="msg-text">Sorry, the page you requested does not exist on this site</p>
        </div>
        <div class="adv" @mouseenter="advMouseenter" v-if="$originCopyWriting != 'hahamini'">
          <div v-if="mNoAfc" :id="gptId" ref="advRef"></div>
          <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
               style="display:block"
               :data-ad-client="caPub"
               :data-ad-slot="slot"
               data-ad-format="auto"
               data-full-width-responsive="true"></ins>
        </div>
        <div class="popular-games">
          <h6 class="title">The Most Popular Games</h6>
          <div class="games">
            <ClassList styleType="1" :gameTypeList="gameList"></ClassList>
          </div>
        </div>
      </div>
      <StartAndEnd :bottomHide="false" />
    </div>
  </div>
</template>

<script>
import ClassList from "@/components/MobileTerminal/MobileHome/ClassList";
import StartAndEnd from "@/components/MobileTerminal/MobileHome/StartAndEnd";
import {advMouseenterPublic, determinePcOrMove, iconClickEscalation, recentGame} from "@/utils/utils";
export default {
  name: "NotFound",
  components: {
    ClassList, StartAndEnd
  },
  data() {
    return {
      gameList: [],
      determinePcOrMove: null,
      slot: null,
      caPub: null,
      noAdvertisements: null, // 1没广告
      mNoAfc: null,
      gptId: null,
      styleAdjust: null,
      urlPCType: null, // 跳转路径为Pc
      newJumpUrl: null,
      jumpSubdomain: null
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { m_404_slot, noAdvertisements, channel_ca_pub, mNoAfc, m_404_gpt, styleAdjust, urlPCType, jumpSubdomain } = channelInfo || {}
    this.slot = m_404_slot
    this.caPub = channel_ca_pub
    this.noAdvertisements = noAdvertisements
    this.mNoAfc = mNoAfc
    let gpt = m_404_gpt && m_404_gpt.split(",") || []
    this.gptId = gpt[3]
    this.styleAdjust = styleAdjust
    this.urlPCType = urlPCType
    this.jumpSubdomain = jumpSubdomain
    // 跳转子域名详情页
    this.newJumpUrl = window.location.protocol + '//' + 'game01' + '.' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements, mNoAfc } = channelInfo || {}
    if (determinePcOrMove() == 1) {
      this.determinePcOrMove = 1
      setTimeout(()=>{
        if (!noAdvertisements && !mNoAfc && this.$originCopyWriting != 'hahamini') {
          window.addAds()
        }
      },1300)
      if (mNoAfc) {
        console.log(window.isDisplay);
        if (window.isDisplay) {
          googletag.cmd.push(function() { googletag.pubads().refresh(); });
        }

        let leftAdv = document.createElement("script")
        leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(mNotFound[3]);window.isDisplay = true};});"
        setTimeout(()=>{
          this.$refs.advRef.append(leftAdv)
        },600)
      }
    } else {
      this.determinePcOrMove = 2
    }
    this.timer = setInterval(()=>{
      if (window.loadOk) {
        clearInterval(this.timer)
        this.getJson()
      }
    },2000)
  },
  methods: {
    getJson() {
      let jsonArr = getAllJson()
      let newArr = []
      jsonArr && jsonArr.map((item)=>{
        newArr.push(item)
      })
      this.gameList = newArr.splice(0,30)
    },
    // 点击跳转详情
    iconClick(item) {
      recentGame(item)
      iconClickEscalation()
      // this.$router.push({
      //   path: '/P/details',
      //   query: {
      //     gameId: item.gameId
      //   }
      // },()=>{})
    },
    advMouseenter() {
      advMouseenterPublic()
    }
  }
}
</script>

<style lang="less" scoped>
@media (hover: hover) {
  .item:hover {
    transform: scale(1.01869) translate(0px, -4px)!important;
  }
  .item:hover::after {
    opacity: 1;
    background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
  }
  .item:hover .opacity {
    opacity: 1!important;
    transform: translate(0px, 0px)!important;
  }
}
.content{
  .msg-404{
    padding-top: 42px;
    padding-bottom: 30px;
    background-color: rgba(0,0,0,.2);
    .msg-title{
      font-size: 146px;
      background-image: linear-gradient(180deg,#75ddff,#c1d8ff);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-shadow: 0.1875rem 0.1875rem 0.125rem hsl(0deg 0% 100%/20%);
      text-align: center;
      line-height: 1;
      font-weight: 400;
    }
    .not-found{
      margin-top: 22px;
      font-size: 26px;
      text-align: center;
      color: #fff;
    }
    .msg-text{
      margin-top: 28px;
      font-size: 20px;
      color: #dde5ff;
      text-align: center;
    }
  }
  .popular-games{
    margin-top: 16px;
    padding: 0 21px;
    .title{
      color: #fff;
      font-size: 30px;
    }
    .games{
      margin-top: 18px;
      box-sizing: border-box;
    }
    .item {
      width: 94px;
      height: 94px;
      float: left;
      margin-right: 16px;
      display: block;
      overflow: hidden;
      margin-bottom: 10px;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        //border: 2px solid #fff;
        border-radius: 16px;
        //background: white;
      }
    }
    .item:hover {
      transform: scale(1.04255) translate(0px, -4px);
      transition-duration: 0.3s;
    }
    .item-1{
      border-radius: 0;
      img{
        border-radius: 0;
      }
    }
    .games:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
    }
    .position {
      position: absolute;
      right: 0px;
      bottom: 0px;
      left: 0px;
      padding: 6px;
      color: rgb(255, 255, 255);
      font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
      text-align: center;
      z-index: 6;
      transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
      transform: translate(0px, 8px);
      opacity: 0;
      -webkit-font-smoothing: antialiased;
      pointer-events: none;
    }
  }
}
.m-content{
  .msg-404{
    padding-top: 2.3333rem;
    padding-bottom: 1.6666rem;
    background-color: rgba(0,0,0,.2);
    .msg-title{
      font-size: 8.1111rem;
      background-image: linear-gradient(180deg,#75ddff,#c1d8ff);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-shadow: 0.1875rem 0.1875rem 0.125rem hsl(0deg 0% 100%/20%);
      text-align: center;
      line-height: 1;
      font-weight: 400;
    }
    .not-found{
      margin-top: 1.2222rem;
      font-size: 1.4444rem;
      text-align: center;
      color: #fff;
    }
    .msg-text{
      margin-top: 1.5555rem;
      font-size: 1.1111rem;
      color: #dde5ff;
      text-align: center;
    }
  }
  .adv{
    margin-top: 16px;
  }
  .popular-games {
    margin: 16px auto 0;
    .title {
      color: #fff;
      font-size: 0.9275rem;
    }
    .games{
      margin-top: 0.625rem;
    }
  }
}
@media (min-width: 111px) and (max-width: 550.9px) {
  .m-content .popular-games {
    width: 314px;
  }
}
@media (min-width: 551px) and (max-width: 660.9px) {
  .m-content .popular-games {
    width: 534px;
  }
}
@media (min-width: 661px) and (max-width: 880.9px) {
  .m-content .popular-games {
    width: 644px;
  }
}
@media (min-width: 881px) {
  .m-content .popular-games {
    width: 864px;
  }
}
</style>