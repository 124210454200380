<template>
  <div>
    <section role="search" :class="determinePcOrMove == 1 ? UnfoldAndCollapse ? big_box : big_box_2  : UnfoldAndCollapse ? big_box_3 : big_box_4">
      <div class="top_box">
        <button class="button_img back_logo" v-if="determinePcOrMove == 1" @click="collapse">
          <img v-if="determinePcOrMove == 1" class="back_icon" :src="fanHui" alt="">
          <img :src="logos" class="logos" alt="">
        </button>
        <div class="search_box">
          <input :placeholder="determinePcOrMove == 1 ? 'search result' : 'What are you going to play today？'" type="search" v-model="screenValue" class="search_input" @input="inputChange($event.target.value)">
          <div class="empty_box">
            <div v-if="screenType" @click="emptyClick">
              <div class="button_img empty_button">
                <img :src="guanBi" alt="">清空
              </div>
            </div>
            <img v-else :src="souSuo2" alt="">
          </div>
        </div>
      </div>
      <section v-show="!screenList.length" class="roll_box">
        <div v-show="screenType" class="tips_box">
          <h1>Hmm, nothing’s coming up for that.</h1>
          <p>Try searching for something else?</p>
        </div>
        <div v-show="!screenType" class="nav_box">
          <nav class="nav_style" id="nav">
            <div class="nav_item" v-for="(item,index) in typeList" :key="index" @click="classClick(item.type)">{{item.type}}</div>
          </nav>
        </div>
        <h2 class="title"><img :src="huo" alt="">Popular this week</h2>
        <div class="game_box">
          <a :href="whereFrom != 2 && jumpSubdomain ? (newJumpUrl + (determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" :class="styleAdjust ? 'sc-wr3rvk-02 app-item app-item-1' : 'sc-wr3rvk-02 app-item'" v-for="(item,index) in popularGame" :key="index" @click="iconClick(item)">
            <img :src="item.iconUrl" alt="">
            <span class="opacity position">{{item.Name}}</span>
            <div class="hot_box" v-if="index == 0">
              <div class="hot_img">
                <img :src="huore" alt="">
              </div>
            </div>
          </a>
        </div>
        <h2 class="title"><img :src="wanGuo" alt="">Recently played</h2>
        <div class="game_box">
          <a :href="whereFrom != 2 && jumpSubdomain ? (newJumpUrl + (determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" :class="styleAdjust ? 'sc-wr3rvk-02 app-item app-item-1' : 'sc-wr3rvk-02 app-item'" v-for="(item,index) in topGameList" :key="index" @click="iconClick(item)">
            <img :src="item.iconUrl" alt="">
            <span class="opacity position">{{item.Name}}</span>
<!--            <div class="hot_box">-->
<!--              <div class="hot_img">-->
<!--                <img :src="shuaXin" alt="">-->
<!--              </div>-->
<!--            </div>-->
          </a>
        </div>
      </section>
      <section v-if="screenList.length" class="type_games_box">
        <div class="games_list_box">
          <div class="games_list" v-if="determinePcOrMove == 1">
            <a v-for="(item,index) in screenList" :key="index" :href="whereFrom != 2 && jumpSubdomain ? (newJumpUrl + (determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" :class="styleAdjust ? 'sc-wr3rvk-02 app-item app-item-1' : 'sc-wr3rvk-02 app-item'" @click="iconClick(item)">
              <img :src="item.iconUrl" class="icon_style" alt="">
              <div style="margin: auto 0">
                <div>
                  <div class="icon_name">{{item.Name}}</div>
                </div>
                <span></span>
              </div>
            </a>
          </div>
          <div class="games_list_pc" v-else>
            <a :href="whereFrom != 2 && jumpSubdomain ? (newJumpUrl + (determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((determinePcOrMove == 1 ? '/M' : (urlPCType ? '/Pc' : '/P')) + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" :class="styleAdjust ? 'sc-wr3rvk-02 app-item app-item-1' : 'sc-wr3rvk-02 app-item'" v-for="(item,index) in screenList" :key="index" @click="iconClick(item)">
              <img :src="item.iconUrl" alt="">
              <span class="opacity position">{{item.Name}}</span>
            </a>
          </div>
        </div>
        <div>
          <TypeList :whereFrom="determinePcOrMove == 1 ? 0 : 1" :whereFrom2="whereFrom" @collapse="collapse"></TypeList>
        </div>
      </section>
      <button class="back_btn back_btn2" @click="collapse">
        <img :src="fanHui2" alt="">
      </button>
    </section>
    <div class="fixed" v-if="UnfoldAndCollapse" @click="collapse"></div>
  </div>
</template>

<script>
import souSuo2 from '@/assets/sousuo2.png';
import fanHui2 from '@/assets/fanhui2.png';
import shuaXin from '@/assets/shuaxin.png';
import huore from '@/assets/huore.png';
import fanHui from '@/assets/fanhui.png';
import guanBi from '@/assets/guanbi.png';
import huo from '@/assets/huoicon.png';
import wanGuo from '@/assets/wanguoicon.png';
import TypeList from '@/components/TypeList.vue'
import {
  getGameTypeList,
  recentGame,
  determinePcOrMove,
  pageInitLog,
  clickGameLog,
  pageOutLog, Observer, detailsPageOutLog, iconClickEscalation
} from '@/utils/utils'
import logo from "/public/img/logos/default.png";
export default {
  name: "SearchFor",
  props: ['UnfoldAndCollapse','whereFrom','portal','gameName'],
  components: {
    TypeList
  },
  data() {
    return {
      big_box_3: 'big_box_3', // pc展开样式
      big_box_4: 'big_box_4', // pc收起样式
      big_box: 'big_box', // 移动端展开样式
      big_box_2: 'big_box_2', // 移动端收起样式
      logos: this.$originCopyWriting == 'hahamini' ? `${window.location.origin}/img/logos/hahamini.png` : logo,
      souSuo2, fanHui2, shuaXin, huore, fanHui, guanBi, huo, wanGuo,
      typeList: getGameTypeList() || [], // 类型
      popularGame: [], // 热门游戏
      screenList: [], // 筛选数据
      screenType: false, // 搜索状态
      topGameList: [], // 常玩游戏
      determinePcOrMove: null,
      screenValue: '', // 搜索框值
      styleAdjust: null,
      urlPCType: null, // 跳转路径为Pc
      newJumpUrl: null,
      jumpSubdomain: null
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { styleAdjust, urlPCType, jumpSubdomain } = channelInfo || {}
    this.styleAdjust = styleAdjust
    this.urlPCType = urlPCType
    this.jumpSubdomain = jumpSubdomain
    // 跳转子域名详情页
    this.newJumpUrl = window.location.protocol + '//' + 'game01' + '.' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    if (determinePcOrMove() == 1) {
      this.determinePcOrMove = 1
      // setTimeout(()=>{
      //   window.addAds()
      // },1300)

      this.exposure()
      // 进入页面埋点
      pageInitLog('mobile_search')
    } else {
      this.determinePcOrMove = 2
      this.exposure()
      // 进入页面埋点
      pageInitLog('pc_search')
    }
    this.navSlide()
    this.getJson()
    this.topGameList = localStorage.getItem('recentGame') && JSON.parse(localStorage.getItem('recentGame'))
  },
  methods: {
    // 曝光
    exposure() {
      if (this.determinePcOrMove == 1) {
        // 获取需要曝光的item
        setTimeout(()=>{
          let itemArr = [...document.getElementsByClassName("sc-wr3rvk-02")]
          itemArr && Array.from(itemArr).map((item)=>{
            Observer('mobile_search').observe(item)
          })
        })
      } else {
        // 获取需要曝光的item
        setTimeout(()=>{
          let itemArr = [...document.getElementsByClassName("sc-wr3rvk-02")]
          itemArr && Array.from(itemArr).map((item)=>{
            Observer('pc_search').observe(item)
          })
        })
      }
    },
    // 收起
    collapse() {
      this.$emit('searchClick')
    },
    // 导航滑动
    navSlide() {
      let flag; // 鼠标按下
      let downX; // 鼠标点击的x下标
      let scrollLeft; // 当前元素滚动条的偏移量
      let nav = document.getElementById('nav')
      console.log(nav);
      if (nav) {
        nav.addEventListener("mousedown", function (event) {
          flag = true;
          downX = event.clientX; // 获取到点击的x下标
          scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
        });
        nav.addEventListener("mousemove", function (event) {
          if (flag) { // 判断是否是鼠标按下滚动元素区域
            // 获取移动的x轴
            let moveX = event.clientX;
            // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
            let scrollX = moveX - downX;
            // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
            this.scrollLeft = scrollLeft - scrollX;
          }
        });
        // 鼠标抬起停止拖动
        nav.addEventListener("mouseup", function () {
          flag = false;
        });
        // 鼠标离开元素停止拖动
        nav.addEventListener("mouseleave", function () {
          flag = false;
        });
      }
    },
    // 获取全部游戏
    getJson() {
      let allJson = getAllJson()
      this.popularGame = allJson.slice(0,6)
    },
    classClick(value) {
      this.screenValue = value
      this.inputChange(value)
    },
    // 搜索框
    inputChange(value,type) {
      let inputValue = value.toLowerCase()
      if (inputValue == '') {
        this.screenType = false
      } else {
        this.screenType = true
      }
      if (inputValue.trim()) {
        let arr = []
        let allJson = getAllJson()
        allJson && allJson.map((item)=>{
          if (item[value.toUpperCase()] == 1) {
            let index = arr.findIndex((items)=>items.gameId == item.gameId)
            if (index == -1) {
              arr.push(item)
            }
          }
          if (item.Name.toLowerCase().includes(`${inputValue}`)) {
            let index = arr.findIndex((items)=>items.gameId == item.gameId)
            if (index == -1) {
              arr.push(item)
            }
          }
        })
        this.screenList = arr
      } else {
        this.screenList = []
      }
      this.exposure()
    },
    // 清空input
    emptyClick() {
      this.screenValue = ''
      this.screenList = []
      this.screenType = false
    },
    // 切换游戏
    iconClick (item) {
      if (this.determinePcOrMove == 1) {
        clickGameLog('mobile_search', item)
        if (this.whereFrom == 2) {
          pageOutLog(this.portal)
          // detailsPageOutLog(this.portal, this.gameName)
        } else {
          pageOutLog(this.portal)
        }
      } else {
        clickGameLog('pc_search', item)
        if (this.whereFrom == 2) {
          pageOutLog(this.portal)
          detailsPageOutLog(this.portal, this.gameName)
        } else {
          pageOutLog(this.portal)
        }
      }
      recentGame(item)
      this.collapse()
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'button', {configId: '903985143796275456'});
        bge && bge('event', 'form_button', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }
      iconClickEscalation()
    },
  },
  beforeRouteLeave(to, from, next){
    console.log(to);
    console.log(from);
    next()
  },
  beforeDestroy() {
    // 离开页面埋点
    if (this.determinePcOrMove == 1) {
      pageOutLog('mobile_search')
    } else {
      pageOutLog('pc_search')
    }
  },
  watch: {
    '$route'(val) {
      // if (window.location.href.split('#')[1]) {
      //
      // } else {
      //   if (val.query.gameId) {
      //     getAllJson().map((item) => {
      //       if (item.gameId == val.query.gameId) {
      //         if (val.name == 'Details' || val.name == 'mobileDetails') {
      //
      //         } else {
      //           if (this.determinePcOrMove == 1) {
      //             clickGameLog('mobile_search', item)
      //           } else {
      //             clickGameLog('pc_search', item)
      //           }
      //           recentGame(item)
      //           if (this.$originCopyWriting == 'ajoy') {
      //             bge && bge('event', 'button', {configId: '903985143796275456'});
      //             sessionStorage.setItem('pixelType','1')
      //           }
      //         }
      //       }
      //     })
      //   }
      //   this.collapse()
      // }
    }
  }
}
</script>

<style lang="less" scoped>
@media (min-width: 111px) and (max-width: 770.9px) {
  .big_box {
    padding: 0px 15px 0px 29px!important;
    width: 100%!important;
    overflow: hidden!important;
    top: 0px!important;
    bottom: 0px!important;
    left: 0px!important;
    border-radius: 0!important;
    .roll_box {
      padding: 84px 21px 0px!important;
    }
    .big_box_2 {
      padding: 0px 15px 0px 29px!important;
      width: 100%!important;
      overflow: hidden!important;
    }
    .top_box {
      height: 60px!important;
      border-radius: 0px 12px 12px 0px!important;
      top: 16px!important;
      left: -29px!important;
      width: 96vw!important;
    }
  }
  .back_btn2 {
    display: none!important;
  }
}
@media (min-width: 111px) and (max-width: 359px) {
  .app-item{
    margin-right: 16px;
  }
}
@media (min-width: 359px) and (max-width: 465px) {
  .app-item:not(:nth-child(3n)){
    margin-right: 16px;
  }
}
@media (min-width: 465px) and (max-width: 576px) {
  .app-item:not(:nth-child(4n)){
    margin-right: 16px;
  }
}
@media (min-width: 576px) and (max-width: 685px) {
  .app-item:not(:nth-child(5n)){
    margin-right: 16px;
  }
}
@media (min-width: 685px) {
  .app-item:not(:nth-child(6n)){
    margin-right: 16px;
  }
}

.big_box_3, .big_box{
  position: fixed;
  z-index: 100003;
  top: 10px;
  bottom: 10px;
  left: 10px;
  background-color: #AEEBFE;
  width: 688px;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 12px 16px 0px;
  display: flex;
  flex-direction: column;
  padding: 32px 29px 0px;
  box-sizing: border-box;
  border-radius: 10px;
}
.big_box_4 {
  position: fixed;
  z-index: 100003;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #AEEBFE;
  width: 688px;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 12px 16px 0px;
  display: flex;
  flex-direction: column;
  padding: 32px 29px 0px;
  transform: translateX(calc(-100% - 32px));
  pointer-events: none;
}
.fixed {
  position: fixed;
  z-index: 100002;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.top_box {
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  width: 612px;
  height: 64px;
  flex-direction: row;
  box-shadow: rgba(9, 30, 66, 0.07) 0px 4px 8px, rgba(9, 30, 66, 0.07) 0px 0px 2px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.back_logo:hover {
  background: #83C8DD;
}
.back_logo.back_logo {
  border-right: 2px solid #83C8DD;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border-radius: 0px 0px 0px 0px;
  background: #83C8DD;
}
.button_img {
  font-size: 100%;
  font-family: inherit;
  border: 0px;
  padding: 0px;
  background: none;
  cursor: pointer;
  .back_icon{
    width: 8px;
    height: 10px;
    margin: 0 8px 0 0;
  }
  .logos{
    width: 24px;
    height: 24px;
  }
}
.search_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.search_input {
  outline: 0px;
  width: 100%;
  height: 100%;
  //margin: 0px 0px 0px 16px;
  border: none;
  font-family: Torus, sans-serif;
  font-size: 21px;
  color: #ffffff;
  min-width: 0px;
  background: #83C8DD;
  padding-left: 10px;
}
.search_input::placeholder{
  color: #ffffff;
}
.empty_box {
  height: 100%;
  display: flex;
  align-items: center;
  background: #83C8DD;
  img{
    width: 56px;
    height: 56px;
    margin: 0 5px;
  }
  .empty_button {
    background: #f0f5fc;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin: 0px 14px;
    font: 700 12px/22px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
    text-transform: uppercase;
    color: #5d6b84;
    overflow: hidden;
    will-change: width;
    transition: width 0.1s ease-in 0s;
    img{
      width: 16px;
      height: 16px;
      margin: 0 10px;
    }
  }
  .empty_button:hover {
    width: 88px;
  }
}
input::placeholder{
  color: #bac9de;
}

input::-webkit-search-cancel-button {
  display: none;
}
.back_btn2 {
  position: absolute;
  top: 32px;
  right: -32px;
  margin: 0px;
  transition: opacity 0.3s ease-out 0s, transform 0.2s ease 0s;
  img{
    width: 25px;
    height: 25px;
  }
}
.back_btn {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 8px 0px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  cursor: pointer;
  transition: border-color 0.2s ease-out 0s, box-shadow 0.2s ease-out 0s, transform 0.2s ease-out 0s;
}
.back_btn:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
  transform: translateY(-2px);
}
.roll_box {
  transition: transform 0.1s ease-out 0s;
  transform: translateY(0px);
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 108px 21px 0px;
  box-sizing: border-box;
}
.roll_box::-webkit-scrollbar {
  display: none;
}
.nav_box {
  margin: 0px -21px;
  position: relative;
  z-index: 2;
  transition: opacity 0.1s ease-out 0s;
  will-change: opacity;
}
.nav_box::before {
   content: "";
   position: absolute;
   top: 0px;
   left: 0px;
   width: 20px;
   height: 72px;
   background: linear-gradient(270deg, rgba(174, 235, 254, 0) 0%, rgb(174, 235, 254) 100%);
   pointer-events: none;
   z-index: 1;
  box-sizing: border-box;
 }
.nav_box::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 120px;
  height: 72px;
  background: linear-gradient(270deg, rgb(174, 235, 254) 20%, rgba(174, 235, 254, 0) 50%);
  pointer-events: none;
  box-sizing: border-box;
}
.nav_style {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
  height: 64px;
  padding: 0px 32px 0px 29px;
  margin: 0px 0px -22px;
}
.nav_item {
  display: inline-block;
  background: rgb(255, 255, 255);
  box-shadow: rgba(9, 30, 66, 0.07) 0px 4px 8px, rgba(9, 30, 66, 0.07) 0px 0px 2px;
  border-radius: 20px;
  color: #002b50;
  padding: 8px 20px;
  font: 700 12px/22px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
  text-transform: uppercase;
  margin: 0px 10px 10px 0px;
  -webkit-tap-highlight-color: transparent;
  height: 36px;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
}
.nav_style::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.nav_item:hover{
  background: #009cff;
  color: rgb(255, 255, 255);
}
.title {
  margin: 24px 8px 0px;
  font: 500 24px Torus, sans-serif;
  img{
    vertical-align: bottom;
    margin-right: 3px;
  }
}
.game_box {
  margin-top: 16px;
  .hot_box {
    position: absolute;
    z-index: 5;
    left: -6px;
    right: 0px;
    height: 30px;
    top: 8px;
    font: 700 11px/28px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
    color: #009cff;
    text-transform: uppercase;
    padding: 0px 8px 0px 6px;
    pointer-events: none;
    .hot_img {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      background: rgb(236, 27, 37);
      box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
      border-radius: 3px 14px 14px 0px;
      //padding: 0px 8px 0px 3px;
      img{
        width: 39px;
        height: 39px;
        //padding: 5px 0px 0px 3px;
        z-index: 2;
        box-sizing: border-box;
      }
    }
    .wan_img{
      background: rgb(27, 106, 236);
      img{
        width: 37px;
        height: 32px;
        //padding: 5px 0px 0px 3px;
        z-index: 2;
        box-sizing: border-box;
      }
    }
  }
  .hot_box:before {
    background: rgb(236, 27, 37);
    bottom: -4px;
    height: 4px;
    width: 4px;
    z-index: 0;
    content: "";
    left: 0;
    position: absolute;
  }
  .wan_box:before {
    background: rgb(27, 106, 236);
    bottom: -4px;
    height: 4px;
    width: 4px;
    z-index: 0;
    content: "";
    left: 0;
    position: absolute;
  }
  .hot_box:after {
    background: #bac9de;
    border-radius: 4px 0 0 4px;
    bottom: -8px;
    height: 8px;
    width: 6px;
    z-index: 1;
    content: "";
    left: 0;
    position: absolute;
  }
  .hot_box:after {
    background: #bac9de;
    border-radius: 4px 0 0 4px;
    bottom: -8px;
    height: 8px;
    width: 6px;
    z-index: 1;
    content: "";
    left: 0;
    position: absolute;
  }
}
.app-item {
  width: 94px;
  height: 94px;
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
  border-radius: 16px;
  img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    //background: white;
  }
}
.app-item::after {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
  z-index: 4;
  transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1),opacity .3s cubic-bezier(.25, .1, .25, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
  border-radius: 16px;
  contain: strict;
}
.app-item:hover {
  transform: scale(1.04255) translate(0px, -4px);
  transition-duration: 0.3s;
}
.app-item:hover::after {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 12px 0px;
}
.app-item-1{
  border-radius: 0;
  img{
    border-radius: 0;
  }
}
.position {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 6px;
  color: rgb(255, 255, 255);
  font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
  text-align: center;
  z-index: 6;
  transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
  transform: translate(0px, 8px);
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
  word-break: break-all;
}
@media (hover: hover) {
  .app-item:hover {
    transform: scale(1.01869) translate(0px, -4px)!important;
  }
  .app-item:hover .opacity {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
.type_games_box {
  transition: transform 0.1s ease-out 0s;
  transform: translateY(-62px);
  overflow: hidden scroll;
  //display: flex;
  //flex-direction: column;
  position: absolute;
  top: 0px;
  bottom: -62px;
  left: 0px;
  width: 100%;
  padding: 150px 21px 0px;
  box-sizing: border-box;
}
.games_list_box {
  position: relative;
  width: calc(100% + 20px);
  //flex-grow: 1;
  margin: 0px 0px 10px;
  //height: 100%;
}
.type_games_box::-webkit-scrollbar {
  display: none;
}
.games_list_pc{
  margin: 48px 21px 0px 0px;
}
.games_list_pc:after{
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.games_list {
  margin: 48px 21px 0px 0px;
  overflow: hidden;
  a{
    text-decoration: none;
  }
}
.list_item {
  font-size: 18px;
  margin: 16px 0;
  display: flex;
}
.icon_style {
  border-radius: var(--borderRadius,8px);
  box-shadow: var(--boxShadow,0 4px 8px 0 rgba(0,0,0,.24));
  background: #bac9de;
  margin: 0px 16px 0px 0px;
  width: 64px;
  height: 64px;
}
.kZbSoa {
  margin: auto 0px;
}
.icon_name {
  margin: 0px;
  font: 500 20px/24px Torus, sans-serif;
  padding: 0px 10px 0px 0px;
  color: #002b50;
}
.tips_box {
  margin: 24px 0px 0px 8px;
  max-width: 612px;
  background: rgb(255, 255, 255);
  padding: 18px 24px;
  h1{
    margin: 0px;
    font: 500 24px Torus, sans-serif;
  }
}
</style>