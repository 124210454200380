<template>
  <div v-title :data-title="gameInfo.Name &&(gameInfo.Name + ' - ' + 'Play' + ' ' + gameInfo.Name + ' Online at ' + $originCopyWriting + '.' + $suffix)">
    <PCLogo offsetName="2" whereFrom="2" portal="pc_detail" :gameName="gameInfo.Name"></PCLogo>
    <div class="details">
      <div class="content_box">
        <div class="function_box">
          <div class="main_game">
            <div class="game_part" :style="full">
              <div class="prompt" v-if="$store.state.deferredPromptType && promptType" @click="closePrompt"><span>Create a shortcut for a quick start next time！</span> <i class="el-icon-close"></i></div>
              <div class="smallHand" v-if="$store.state.deferredPromptType && promptType" :style="remindCursorBg"></div>
              <div class="game_container">
                <iframe :src="gameInfo.Urlpc ? gameInfo.Urlpc : null" width="100%" height="100%" id="iframe"></iframe>
              </div>
              <div class="game_bar">
                <div class="bar_app_icon">
                  <img :src="gameInfo.iconUrl" alt="">
                  <div class="text_box">
                    <div><h1>{{ gameInfo.Name }}</h1></div>
                    <span>{{ gameInfo.Type }}</span>
                  </div>
                </div>
                <div class="logo1 logo2" v-if="isBlock">
                  <img :src="logo" alt="">
                </div>
                <div class="bar_btns" v-if="isBlock">
                  <div class="reduce_box" @click="closeClick">
                    <div class="reduce_1">
                      <div class="reduce_2 reduce_3">
                        <img :src="reduce" alt="">
                      </div>
                    </div>
                    <div class="button_box">
                      <span class="button_box_1 button_box_2">Minimize</span>
                      <span class="button_box_1 button_box_3">Minimize</span>
                    </div>
                  </div>
                </div>
                <div class="bar_btns" v-else>
                  <div class="btns_box">
                    <div :class="likeStyle ? 'reduce_box' : 'like_style'" @click="btnClick(1)">
                      <div class="like_style_1" :class="likeStyle ? 'reduce_1' : 'like_style_2'">
                        <div class="reduce_2" :class="likeStyle ? 'reduce_3' : 'like_style_3'">
                          <img v-if="!likeStyle" :src="like" alt="">
                          <img v-if="likeStyle" :src="likeWhite" alt="">
                        </div>
                      </div>
                      <div class="button_box">
                        <span class="button_box_1 like_style_4">{{likeScore}}k</span>
                        <span class="button_box_1 button_box_3">{{likeStyle ? 'remove' : 'like'}}</span>
                      </div>
                    </div>
                    <div :class="dislikeStyle ? 'dislike_style' : 'like_style'" @click="btnClick(2)">
                      <div class="like_style_1" :class="dislikeStyle ? 'dislike_style_1' : 'dislike_style_2'">
                        <div :class="dislikeStyle ? 'dislike_style_3' : 'dislike_style_4'">
                          <img v-if="!dislikeStyle" :src="dislike" alt="">
                          <img v-if="dislikeStyle" :src="dislikeWhite" alt="" style="margin-left: 1px">
                        </div>
                      </div>
                      <div class="button_box">
                        <span class="button_box_1 like_style_4">{{dislikeScore}}k</span>
                        <span class="button_box_1 button_box_3">{{dislikeStyle ? 'remove' : 'dislike'}}</span>
                      </div>
                    </div>
                  </div>
<!--                  <div :class="flagStyle ? 'reduce_box' : 'full_btn'" @click="btnClick(3)">-->
<!--                    <div class="like_style_1" :class="flagStyle ? 'reduce_1' : 'like_style_2'" :style="flagStyle ? {lineHeight: '30px'} : null">-->
<!--                      <div class="reduce_2" :class="flagStyle ? 'reduce_3' : 'like_style_3'" :style="flagStyle ? {textAlign: 'center'} : null">-->
<!--                        <img v-if="!flagStyle" :src="flag" alt="">-->
<!--                        <img v-if="flagStyle" :src="flagWhite" alt="" style="width: 17px;height: 17px; margin-left: 1px">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="button_box">-->
<!--                      <span class="button_box_1 like_style_4"></span>-->
<!--                      <span class="button_box_1 button_box_3">{{flagStyle ? '' : 'Report an error'}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
                  <el-divider direction="vertical"></el-divider>
                  <div class="full_btn_download" @click="addToDesktop">
                    <div class="like_style_1 like_style_2">
                      <div class="like_style_3">
                        <img :src="download" alt="">
                      </div>
                    </div>
                    <div class="button_box">
                      <span class="button_box_1 like_style_4"></span>
                      <span class="button_box_1 button_box_3">Add to Desktop</span>
                    </div>
                  </div>
                  <div class="full_btn" @click="amplifyClick">
                    <div class="like_style_1 like_style_2">
                      <div class="like_style_3">
                        <img :src="amplify" alt="">
                      </div>
                    </div>
                    <div class="button_box">
                      <span class="button_box_1 like_style_4"></span>
                      <span class="button_box_1 button_box_3">maximize</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: contents">
          <a :href="(urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" v-for="(item,index) in bigImgList" :key="index" :class="styleAdjust ? 'sc-wr3rvk-0 a_style_1 a_style_2 a_style_3 a_style_4' : 'sc-wr3rvk-0 a_style_1 a_style_2 a_style_3'" :style="innerWidth >= 1321 ? {gridArea: 'bigIp' + index} : null" @click="iconClick(item)">
            <img v-lazy="item.iconUrl" alt="" class="img_style">
            <span class="opacity position">{{item.Name}}</span>
          </a>
        </div>
        <div style="display: contents">
          <a :href="(urlPCType ? '/Pc' : '/P') + '/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" v-for="(item,index) in smallImgList" :key="index" :class="styleAdjust ? 'sc-wr3rvk-0 a_style_1 a_style_2 a_style_4' : 'sc-wr3rvk-0 a_style_1 a_style_2'" @click="iconClick(item)">
            <img v-lazy="item.iconUrl" alt="" style="width: 94px;height: 94px" class="img_style">
            <span class="opacity position">{{item.Name}}</span>
          </a>
        </div>
        <div class="adv_box adv_box_1">
          <div class="adv_box_2">
            <div class="adv_box_3 adv" style="height: 90px;width: 728px;overflow: hidden">
              <div v-if="noAfc" :id="gtpBottomId" ref="bottomAdv"></div>
              <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                   style="display:block;width: 728px;height: 90px"
                   :data-ad-client="caPub"
                   :data-ad-slot="bottomSlot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <div class="adv_box_4"></div>
          </div>
        </div>
        <div class="adv_box adv_box_5">
          <div class="adv_box_6">
            <div class="adv_box_3 adv" style="height: 250px; width: 300px; overflow: hidden;">
              <div v-if="noAfc" :id="gtpRightId" ref="rightAdv"></div>
              <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                   style="display:block;width: 300px;height: 250px"
                   :data-ad-client="caPub"
                   :data-ad-slot="rightSlot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <div class="adv_box_7">advertisement</div>
          </div>
        </div>
        <div class="adv_box adv_box_8">
          <div class="adv_box_6">
            <div style="width: 160px;height: 600px;overflow: hidden" class="adv_box_3 adv">
              <div v-if="noAfc" :id="gtpLeftId" ref="leftAdv"></div>
              <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                   style="display:block;width: 160px;height: 600px"
                   :data-ad-client="caPub"
                   :data-ad-slot="leftSlot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <div class="adv_box_7">
              advertisement
            </div>
          </div>
        </div>
      </div>
      <div style="display: contents">
        <aside class="aside">
          <div class="feedback" v-if="feedbackType1">
            <header class="feedback_header">
              <div class="feedback_1">
                <div class="feedback_2">
                  <div class="btn_img_box">
                    <img v-if="btnClickStatus == 1" :src="likeBlack" alt="">
                    <img v-if="btnClickStatus == 2" :src="dislikeBlack" alt="">
                    <img v-if="btnClickStatus == 3" :src="flagBlack" alt="">
                  </div>
                  <div class="desc">
                    {{btnClickStatus == 1 ? 'What do you like' : btnClickStatus == 2 ? 'What do you dislike' : 'You are'}}
                    <strong>{{gameInfo.Name}}?</strong>
                    {{btnClickStatus == 3 ? 'What vulnerabilities were found in' : ''}}
                  </div>
                </div>
              </div>
              <button class="feed_back_close" @click="feedbackClose">
                <span class="feed_back_close_1 feed_back_close_2"></span>
                <div class="close">
                  <i class="el-icon-close"></i>
                </div>
              </button>
            </header>
            <div class="form_box">
              <el-form ref="form" :model="formData">
                <el-form-item>
                  <el-input
                      v-model="formData.message"
                      name="message"
                      type="textarea"
                      :rows="5"
                      :placeholder="messagePlaceholder"
                  ></el-input>
                </el-form-item>
              </el-form>
              <img :src="star_feedback_v2" alt="" class="guangjian">
            </div>
            <div class="send_box">
              <button class="sending" @click="sendMessage('form')">sending</button>
            </div>
          </div>
          <div :class="feedbackStyle ? 'feedback' : 'feedback_3'" v-if="feedbackType">
            <header class="feedback_header">
              <div class="feedback_1">
                <div class="feedback_2">
                  <div class="btn_img_box">
                    <img v-if="btnClickStatus == 1" :src="likeBlack" alt="">
                    <img v-if="btnClickStatus == 2" :src="dislikeBlack" alt="">
                    <img v-if="btnClickStatus == 3" :src="flagBlack" alt="">
                  </div>
                  <div class="desc">
                    {{btnClickStatus == 1 ? 'What do you like' : btnClickStatus == 2 ? 'What do you dislike' : 'You are'}}
                    <strong>{{gameInfo.Name}}?</strong>
                    {{btnClickStatus == 3 ? 'What vulnerabilities were found in' : ''}}
                  </div>
                </div>
              </div>
              <button class="feed_back_close" @click="feedbackClose">
                <span class="feed_back_close_1 feed_back_close_2"></span>
                <div class="close">
                  <i class="el-icon-close"></i>
                </div>
              </button>
            </header>
            <div class="form_box">
              <el-form ref="form" :model="formData">
                <el-form-item>
                  <el-input
                      v-model="formData.message"
                      name="message"
                      type="textarea"
                      :rows="5"
                      :placeholder="messagePlaceholder"
                  ></el-input>
                </el-form-item>
              </el-form>
              <img :src="star_feedback_v2" alt="" class="guangjian">
            </div>
            <div class="send_box">
              <button class="sending" @click="sendMessage('form')">sending</button>
            </div>
          </div>
          <div class="messageBg" :style="messageBg" v-if="thankType">
            Thank you
          </div>
        </aside>
      </div>
      <div style="display: contents" v-if="smegmaType">
        <div class="masking masking_1" style="background-color: #AEEBFE">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div style="margin-top: 16px">
        <TypeList whereFrom="3"></TypeList>
      </div>
      <div class="bottom-text">
<!--        <nav class="sc-1oa7ili-0 gmEuRM">-->
<!--          <ul>-->
<!--            <li><a :href="(urlPCType ? '/Pc' : '/P') + '/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')">GAME</a></li>-->
<!--            <li>{{ gameInfo.Type }}</li>-->
<!--          </ul>-->
<!--        </nav>-->
        <header class="header_title">
          <h2>{{ gameInfo.Name }}</h2>
        </header>
        <div class="star_box">
          <span class="star"><i class="el-icon-star-on"></i> {{gameScore}}</span>
        </div>
        <div class="bottom_desc">
          {{ gameInfo.desc ? gameInfo.desc : `Play best game on ${$originCopyWriting.toUpperCase()}!` }}
        </div>
        <ul class="type_style type_style_2">
          <li v-for="(item,index) in typeList" :key="index">
            <a :href="jumpSubdomain ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="classClick(item.type)">
              {{item.type}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <BottomNav portal="pc_detail" :gameName="gameInfo.Name"></BottomNav>
  </div>
</template>

<script>
import BottomNav from '../BottomNav';
import TypeList from '@/components/TypeList.vue';
import PCLogo from "@/components/PCLogo.vue";
import {
  determinePcOrMove,
  shuffle,
  setMeta,
  recentGame,
  getGameTypeList,
  pageInitLog,
  pageOutLog, clickGameLog, Observer, detailsPageInitLog, detailsPageOutLog, iconClickEscalation, clickClassificationLog
} from '@/utils/utils.js';
import amplify from '@/assets/amplify.png';
import reduce from '@/assets/reduce.png';
import logo from '@/assets/logo.png';
import like from '@/assets/like.png';
import dislike from '@/assets/dislike.png';
import flag from '@/assets/flag.png';
import likeBlack from '@/assets/likeBlack.png';
import dislikeBlack from '@/assets/dislikeBlack.png';
import flagBlack from '@/assets/flagBlack.png';
import star_feedback_v2 from '@/assets/star_feedback_v2.svg';
import message from '@/assets/message.png';
import likeWhite from '@/assets/likeWhite.png';
import dislikeWhite from '@/assets/dislikeWhite.png';
import flagWhite from '@/assets/flagWhite.png';
import download from '@/assets/installButton.png';
import remindCursor from '@/assets/remindCursor.png';
import request from "@/utils/request";
export default {
  name: "detailsIndex",
  components: {
    BottomNav, TypeList, PCLogo
  },
  data() {
    return {
      gameInfo: {}, // 游戏详情数据
      full: null,
      fullStyle: {
        position: "fixed",
        width: "100%!important",
        height: "100%!important",
        left: 0,
        top: 0,
        zIndex: 999,
        background: '#002b50'
      },
      isBlock: false, // 展示大屏广告状态
      typeList: [], // 游戏类型
      gameScore: "", // 游戏评分
      UnfoldAndCollapse: false, // 展开收起
      bigImgList: [], // 大图片列表
      smallImgList: [], // 小图片列表
      innerWidth: 0, // 屏幕宽度
      amplify,
      reduce,
      logo,
      like,
      dislike,
      flag,
      likeBlack,
      dislikeBlack,
      flagBlack,
      star_feedback_v2,
      likeWhite,
      dislikeWhite,
      flagWhite,
      download,
      messageBg: {
        backgroundImage: `url(${message})`,
        backgroundSize: '100% 100%'
      },
      remindCursorBg: {
        backgroundImage: `url(${remindCursor})`,
        backgroundSize: '100% 100%'
      },
      likeScore: "", // 喜欢
      dislikeScore: "", // 不喜欢
      formData: {
        message: '',
      },
      messagePlaceholder: '', //
      btnClickStatus: 1, // 1喜欢/2不喜欢/3反馈
      feedbackType: false, // 反馈框状态
      feedbackType1: false, // 反馈框状态
      feedbackStyle: false, // 反馈框状态
      thankType: false, // 发送成功提示
      likeStyle: false, // 喜欢样式
      dislikeStyle: false, // 不喜欢样式
      flagStyle: false, // 反馈样式
      smegmaType: false, // 蒙层状态
      caPub: '',
      leftSlot: '',
      rightSlot: '',
      bottomSlot: '',
      time: null, //  安装提示定时器
      promptType: false, // 安装提示状态
      noAdvertisements: null, // 1没广告
      noAfc: null, // 1gtp
      gtpLeftId: '',
      gtpRightId: '',
      gtpBottomId: '',
      styleAdjust: null,
      urlPCType: null, // 跳转路径为Pc
      newJumpUrl: null,
      jumpSubdomain: null,
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { channel_ca_pub, p_dtl_left, p_dtl_right, p_dtl_bottom, noAdvertisements, noAfc, p_dtl_gpt_left, p_dtl_gpt_right, p_dtl_gpt_bottom, styleAdjust, urlPCType, jumpSubdomain } = channelInfo || {}
    this.caPub = channel_ca_pub
    this.leftSlot = p_dtl_left
    this.rightSlot = p_dtl_right
    this.bottomSlot = p_dtl_bottom
    this.noAdvertisements = noAdvertisements
    this.noAfc = noAfc
    let gtpL = p_dtl_gpt_left && p_dtl_gpt_left.split(",") || []
    let gtpR = p_dtl_gpt_right && p_dtl_gpt_right.split(",") || []
    let gtpB = p_dtl_gpt_bottom && p_dtl_gpt_bottom.split(",") || []
    this.gtpLeftId = gtpL[3]
    this.gtpRightId = gtpR[3]
    this.gtpBottomId = gtpB[3]
    this.styleAdjust = styleAdjust
    this.urlPCType = urlPCType
    this.jumpSubdomain = jumpSubdomain
    this.newJumpUrl = window.location.protocol + '//' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    console.log(window.name);
    if (window.name == '') {
      window.name = 'isReload'
    } else if (window.name == 'isReload'){
      console.log('刷新页面');

      const { params } = this.$route
      const { gameName } = params || {}
      // 离开页面埋点
      pageOutLog('pc_detail')
      detailsPageOutLog('pc_detail', gameName)
      localStorage.setItem('timeLength', '0')
      localStorage.setItem('timeOn', '0')
    }
    window.addEventListener('unload', this.handleWindowUnload)
    const { query, params } = this.$route
    const { gameId } = query || {}
    const { gameName } = params || {}
    if (determinePcOrMove() == 1) {
      let { channel } = this.$route.query
      this.$router.push({
        path: `/M/details${gameName ? '/'+gameName : ''}`,
        query: {
          gameId,
          channel
        }
      },()=>{})
    } else {
      let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
      const { noAdvertisements, noAfc } = channelInfo || {}

      // 蒙层状态
      this.smegmaType = true

      this.timer = setInterval(()=>{
        if (window.loadOk) {
          clearInterval(this.timer)
          this.smegmaType = false
          this.getJson()
          if (!noAdvertisements && !noAfc) {
            window.addAds()
          }
          // 获取需要曝光的item
          setTimeout(()=>{
            let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")]
            itemArr && Array.from(itemArr).map((item)=>{
              Observer('pc_detail').observe(item)
            })
          })
        }
      },800)
      document.documentElement.scrollTop = 0
      window.onresize = () => {
        this.innerWidth = window.innerWidth
        if (!this.checkFull()) {
          // 退出全屏后要执行的动作
          this.closeClick()
        }
      }
      this.innerWidth = window.innerWidth
      this.$store.commit('changeScreen', false)

      if (noAfc) {
        // 加载gtp广告
        console.log(window.isDisplay);
        if (window.isDisplay) {
          googletag.cmd.push(function() { googletag.pubads().refresh(); });
        }

        let leftAdv = document.createElement("script")
        leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(pcl[3]);};});"
        setTimeout(()=>{
          this.$refs.leftAdv.append(leftAdv)
        },600)

        let rightAdv = document.createElement("script")
        rightAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(pcr[3]);};});"
        setTimeout(()=>{
          this.$refs.rightAdv.append(rightAdv)
        },900)

        let bottomAdv = document.createElement("script")
        bottomAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(pcb[3]); window.isDisplay = true};});"
        setTimeout(()=>{
          this.$refs.bottomAdv.append(bottomAdv)
        },1200)
      }
    }
  },
  methods: {
    addToDesktop() {
      console.log(this.$store.state.deferredPrompt);
      this.$store.state.deferredPrompt && this.$store.state.deferredPrompt.prompt();
      this.$store.commit("changeInstallPageClick",'2')
      this.$store.commit("changePWA",{deferredPrompt: null,deferredPromptType: this.$store.state.deferredPromptType})
    },
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      let isFull =
          document.mozFullScreen ||
          document.fullScreen ||
          //谷歌浏览器及Webkit内核浏览器
          document.webkitIsFullScreen ||
          document.webkitRequestFullScreen ||
          document.mozRequestFullScreen ||
          document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    // 点击搜索
    searchClick() {
      this.UnfoldAndCollapse = !this.UnfoldAndCollapse
    },
    getJson() {
      // 开始时间
      let timeOn = new Date().getTime()
      localStorage.setItem('timeOn',timeOn.toString())

      document.documentElement.scrollTop = 0

      this.typeList = getGameTypeList() || []

      // 游戏评分
      let score = Math.random()*0.8 + 4.2
      this.gameScore = score.toFixed(1)

      // 喜欢
      let likeScore = Math.random()*1900 + 100
      this.likeScore = likeScore.toFixed(1)

      // 不喜欢
      let dislikeScore = Math.random()*(likeScore.toFixed(1) / 10) + 1
      this.dislikeScore = dislikeScore.toFixed(1)
      clearInterval(this.time)
      let cont = 0
      this.time = setInterval(()=>{
        cont+=1
        if (cont >= 180) {
          this.promptType = true
          clearInterval(this.time)
        }
      },1000)

      const { query } = this.$route
      const { gameId, channel } = query || {}
      let jsonArr = getAllJson()
      // 随机打乱数组
      let shuffleArr = shuffle(jsonArr)
      let gameInfo = {}
      let bigImgList = [] // 大图片列表
      let smallImgList = [] // 小图列表
      shuffleArr && shuffleArr.map((item)=>{
        if (item.gameId == gameId) {
          gameInfo = item
        }
      })
      shuffleArr && shuffleArr.map((item)=>{
        if (item.ImgSize == 1 || item.ImgSize == 2) {
          if (bigImgList.length < 2) {
            bigImgList.push(item)
          } else {
            smallImgList.push(item)
          }
        } else {
          smallImgList.push(item)
        }
      })
      // 渠道
      if (channel) {
        gameInfo.Urlpc = gameInfo.Urlpc + `?channel=` + channel
      }
      // 进入页面埋点
      pageInitLog('pc_detail')
      detailsPageInitLog('pc_detail',gameInfo.Name)
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'ec_detail_view', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }
      this.gameInfo = gameInfo
      this.bigImgList = bigImgList
      this.smallImgList = smallImgList



      setMeta(`${gameInfo.Name},${gameInfo.Name} Online,${gameInfo.Name} for free`,`${gameInfo.Name} is a ${gameInfo.Type} Games`)
    },
    // 关闭安装提示
    closePrompt() {
      this.promptType = false
    },
    // 切换游戏
    iconClick (item) {
      // 刷新广告
      // googletag.cmd.push(function() { googletag.pubads().refresh(); });
      // 打点
      clickGameLog('pc_detail', item)
      recentGame(item)
      this.full = null
      this.isBlock = false
      // 离开页面埋点
      pageOutLog('pc_detail')
      detailsPageOutLog('pc_detail', this.gameInfo.Name)
      localStorage.setItem('timeLength', '0')
      localStorage.setItem('timeOn', '0')
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'button', {configId: '903985143796275456'});
        bge && bge('event', 'form_button', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }
      iconClickEscalation()

      window.stop ? window.stop() : document.execCommand("Stop");
    },
    // 点击放大游戏
    amplifyClick() {
      // 放大全屏
      this.$store.commit('changeScreen', true)
      const { documentElement } = document;
      if (documentElement.requestFullscreen) {
        documentElement.requestFullscreen();
      } else if (documentElement.mozRequestFullScreen) {
        documentElement.mozRequestFullScreen();
      } else if (documentElement.webkitRequestFullScreen) {
        documentElement.webkitRequestFullScreen();
      }
      this.full = this.fullStyle
      this.isBlock = true
    },
    // 大屏点击关闭
    closeClick() {
      this.$store.commit('changeScreen', false)
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
      this.full = null
      this.isBlock = false
    },
    classClick(type) {
      clickClassificationLog('pc_detail',type)
    },
    // 点击喜欢/不喜欢/反馈
    btnClick(status) {
      if (status == 1) {
        this.likeStyle = !this.likeStyle
        if (this.likeStyle) {
          if (this.dislikeStyle || this.flagStyle) {
            this.feedbackType1 = true
            setTimeout(()=>{
              this.feedbackType1 = false
            },500)
          }
          this.dislikeStyle = false
          this.flagStyle = false
          this.feedbackType = false
          this.feedbackStyle = false
          setTimeout(()=>{
            this.btnClickStatus = 1
            this.feedbackType = true
            this.messagePlaceholder = 'please specify......'
          })
        } else {
          this.flagStyle = false
          this.feedbackStyle = true
          setTimeout(()=>{
            this.feedbackType = false
          },500)
        }
      } else if (status == 2) {
        this.dislikeStyle = !this.dislikeStyle
        if (this.dislikeStyle) {
          if (this.likeStyle || this.flagStyle) {
            this.feedbackType1 = true
            setTimeout(()=>{
              this.feedbackType1 = false
            },500)
          }
          this.likeStyle = false
          this.flagStyle = false
          this.feedbackType = false
          this.feedbackStyle = false
          setTimeout(()=>{
            this.btnClickStatus = 2
            this.feedbackType = true
            this.messagePlaceholder = 'please specify......'
          })
        } else {
          this.flagStyle = false
          this.feedbackStyle = true
          setTimeout(()=>{
            this.feedbackType = false
          },500)
        }
      } else {
        this.flagStyle = !this.flagStyle
        if (this.flagStyle) {
          if (this.likeStyle || this.dislikeStyle) {
            this.feedbackType1 = true
            setTimeout(()=>{
              this.feedbackType1 = false
            },500)
          }
          this.feedbackType = false
          setTimeout(()=>{
            this.btnClickStatus = 3
            this.feedbackType = true
            this.feedbackStyle = false
            this.messagePlaceholder = 'Help us understand the vulnerabilities you have discovered......'
          })
        } else {
          this.feedbackStyle = true
          setTimeout(()=>{
            this.feedbackType = false
          },500)
        }
      }
    },
    // 关闭反馈窗
    feedbackClose() {
      this.feedbackType = false
    },
    // 发送
    sendMessage(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          request({
            url: 'https://formspree.io/f/xrgvjyay',
            method: 'post',
            data: {
              name: this.gameInfo.Name,
              message: this.formData.message
            }
          }).then((res)=>{
            console.log(res);
            if (res.status == 200 && res.data.ok) {
              // this.$message.success("发送成功")
              this.feedbackType = false
              this.thankType = true
              setTimeout(()=>{
                this.thankType = false
              },3000)
            }
          }).catch((err)=>{
            console.log(err);
          })
        }
      })
    },
    handleWindowUnload() {
      detailsPageOutLog('pc_detail', this.gameInfo.Name)
    },
  },
  beforeRouteLeave(to, from, next){
    console.log(to);
    console.log(from);
    window.name = ''
    next()
  },
  beforeDestroy() {
    clearInterval(this.time)
    // 离开页面埋点
    pageOutLog('pc_detail')
    detailsPageOutLog('pc_detail',this.gameInfo.Name)
    localStorage.setItem('timeLength', '0')
    localStorage.setItem('timeOn', '0')
  },
  destroyed() {
    window.removeEventListener('unload', this.handleWindowUnload)
  },
  watch: {
    '$route'(val,old) {
      // if (window.location.href.split('#')[1]) {
      //
      // } else {
      //   console.log(val,old,'数据更新了');
      //   if (old.query.gameId) {
      //     getAllJson().map((item)=>{
      //       if (item.gameId == old.query.gameId) {
      //         // 离开页面埋点
      //         pageOutLog('pc_detail')
      //         detailsPageOutLog('pc_detail', old.params.gameName)
      //       }
      //     })
      //   }
      //   if (val.query.gameId) {
      //     getAllJson().map((item)=>{
      //       if (item.gameId == val.query.gameId) {
      //         clickGameLog('pc_detail', item)
      //         recentGame(item)
      //         if (this.$originCopyWriting == 'ajoy') {
      //           bge && bge('event', 'button', {configId: '903985143796275456'});
      //           sessionStorage.setItem('pixelType','1')
      //         }
      //       }
      //     })
      //   }
      //   this.full = null
      //   this.isBlock = false
      //   document.documentElement.scrollTop = 0
      //   // 蒙层状态
      //   this.smegmaType = true
      //   setTimeout(()=>{
      //     this.smegmaType = false
      //   },800)
      //   this.getJson()
      //   window.name = 'isReload'
      // }
    }
  }
}
</script>

<style lang="less" scoped>
@media (min-width: 1871px) {
  .details {
    width: 1854px;
  }
  .content_box {
    --gridTemplateColumns: 17!important;
    grid-template-areas:
        ". . igc igc igc igc igc igc igc igc igc igc bigIp0 bigIp0 adv3 adv3 adv3"
        "adv1 adv1 igc igc igc igc igc igc igc igc igc igc bigIp0 bigIp0 adv3 adv3 adv3"
        "adv1 adv1 igc igc igc igc igc igc igc igc igc igc bigIp1 bigIp1 adv3 adv3 adv3"
        "adv1 adv1 igc igc igc igc igc igc igc igc igc igc bigIp1 bigIp1 . . ."
        "adv1 adv1 igc igc igc igc igc igc igc igc igc igc . . . . ."
        "adv1 adv1 igc igc igc igc igc igc igc igc igc igc . . . . ."
        "adv1 adv1 . . . adv2 adv2 adv2 adv2 adv2 adv2 adv2 . . . . .";
    .game_part {
      width: 1031px!important;
      height: 644px!important;
    }
    .adv_box_8 {
      display: flex;
    }
  }
  .content_box::before {
    grid-column-start: span 2;
  }
}
@media (min-width: 1541px) and (max-width: 1870.9px) {
  .details {
    width: 1524px;
  }
  .content_box {
    --gridTemplateColumns: 14!important;
    grid-template-areas:
        ". igc igc igc igc igc igc igc igc igc igc adv3 adv3 adv3"
        ". igc igc igc igc igc igc igc igc igc igc adv3 adv3 adv3"
        ". igc igc igc igc igc igc igc igc igc igc adv3 adv3 adv3"
        ". igc igc igc igc igc igc igc igc igc igc bigIp0 bigIp0 ."
        ". igc igc igc igc igc igc igc igc igc igc bigIp0 bigIp0 ."
        ". igc igc igc igc igc igc igc igc igc igc bigIp1 bigIp1 ."
        ". . . . adv2 adv2 adv2 adv2 adv2 adv2 adv2 bigIp1 bigIp1 .";
    .game_part {
      width: 1031px!important;
      height: 644px!important;
    }
    .adv_box_8 {
      display: none!important;
    }
  }
}
@media (min-width: 1321px) and (max-width: 1540.9px) {
  .details {
    width: 1304px;
  }
  .content_box {
    --gridTemplateColumns: 12!important;
    grid-template-areas:
        ". igc igc igc igc igc igc igc igc adv3 adv3 adv3"
        ". igc igc igc igc igc igc igc igc adv3 adv3 adv3"
        ". igc igc igc igc igc igc igc igc adv3 adv3 adv3"
        ". igc igc igc igc igc igc igc igc bigIp0 bigIp0 ."
        ". igc igc igc igc igc igc igc igc bigIp0 bigIp0 ."
        ". . adv2 adv2 adv2 adv2 adv2 adv2 adv2 bigIp1 bigIp1 ."
        ". . . . . . . . . bigIp1 bigIp1 .";
    .game_part {
      width: 836px!important;
      height: 534px!important;
    }
    .adv_box_8 {
      display: none!important;
    }
  }
}
@media (min-width: 1211px) and (max-width: 1320.9px) {
  .details {
    width: 1194px;
  }
  .content_box {
    --gridTemplateColumns: 11;
    grid-template-areas:
        ". igc igc igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc igc igc adv1 adv1"
        ". . adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv1 adv1";
    .game_part {
      width: 836px!important;
      height: 534px!important;
    }
    .adv_box_8 {
      display: flex;
    }
    .adv_box_5 {
      display: none!important;
    }
  }
}
@media (min-width: 991px) and (max-width: 1210.9px) {
  .details {
    width: 980px;
  }
  .content_box {
    --gridTemplateColumns: 9!important;
    grid-template-areas:
        ". igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc adv1 adv1"
        ". igc igc igc igc igc igc adv1 adv1"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv1 adv1"
        ". . . . . . . adv1 adv1";
    .game_part {
      width: 640px!important;
      height: 424px!important;
    }
    .adv_box_8 {
      display: flex;
    }
    .adv_box_5 {
      display: none!important;
    }
  }
}
@media (min-width: 111px) and (max-width: 990.9px) {
  .details {
    width: 760px;
  }
  .content_box {
    --gridTemplateColumns: 7!important;
    grid-template-areas:
        ". igc igc igc igc igc igc"
        ". igc igc igc igc igc igc"
        ". igc igc igc igc igc igc"
        ". igc igc igc igc igc igc"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2";
    .adv_box_8 {
      display: none!important;
    }
    .adv_box_5 {
      display: none!important;
    }
  }
}
@media (min-width: 1321px) {
  .adv_box_5 {
    display: flex;
  }
  .a_style_3{
    img{
      width: 204px!important;
      height: 204px!important;
    }
  }
}
.details{
  margin: 0px auto;
  flex-grow: 1;
  .content_box {
    display: grid;
    grid-template-rows: repeat(auto-fill, 94px);
    grid-gap: 16px;
    grid-auto-flow: dense;
    justify-content: center;
    margin: 16px auto 0px;
    padding: 0px;
    list-style-type: none;
    --gridTemplateColumns: 3;
    grid-template-columns: repeat(var(--gridTemplateColumns),94px);
    .function_box {
      grid-area: igc;
      display: flex;
      justify-content: center;
    }
    .adv_box_8 {
      display: none;
      grid-area: adv1;
      .adv_box_6 {
        display: inline-flex;
        flex-direction: column;
        .adv_box_3 {
          background: rgba(255, 255, 255, 0.5);
        }
        .adv_box_7 {
          font: 400 9px / 2 Arial, sans-serif;
          //text-transform: uppercase;
          text-align: center;
          opacity: 0.7;
          height: 20px;
          letter-spacing: 1px;
          color: #002b50;
        }
      }
    }
    .adv_box_1 {
      grid-area: adv2;
      .adv_box_2 {
        display: inline-flex;
        flex-direction: row;
        padding-right: 26px;
        position: relative;
        .adv_box_3 {
          background: rgba(255, 255, 255, 0.5);
        }
        .adv_box_4 {
          font: 400 8px / 1 Arial, sans-serif;
          //text-transform: uppercase;
          text-align: center;
          opacity: 0.7;
          height: 20px;
          letter-spacing: 1px;
          color: #002b50;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: rotate3d(0, 0, 1, 90deg) translateX(50%) translate(-10px, 24px);
          transform-origin: right center;
        }
      }
    }
    .adv_box_5 {
      display: none;
      grid-area: adv3;
      height: 314px;
      .adv_box_6 {
        display: inline-flex;
        flex-direction: column;
        .adv_box_3 {
          background: rgba(255, 255, 255, 0.5);
        }
        .adv_box_7{
          font: 400 9px / 2 Arial, sans-serif;
          //text-transform: uppercase;
          text-align: center;
          opacity: 0.7;
          height: 20px;
          letter-spacing: 1px;
          color: #002b50;
        }
      }
    }
    .adv_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  .content_box::before {
    content: "";
    display: block;
  }
  #iframe{
    border: 1px solid #cccccc;
  }
  .main_game {
    width: 100%;
    height: 100%;
    .game_part {
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 0;
      width: 100%;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      position: relative;
      @keyframes heart{
        0% {
          transform: translateY(-5px) rotate(180deg);
        }
        100% {
          transform: translateY(10px) rotate(180deg);
        }
      }
      .smallHand{
        width: 48px;
        height: 37px;
        position: absolute;
        bottom: 50px;
        right: 111px;
        z-index: 5;
        animation: heart .5s ease-in-out infinite alternate;
      }
      .prompt{
        padding: 12px;
        position: absolute;
        bottom: 80px;
        right: 0;
        background: #fff;
        border-radius: 13px;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.2);
        z-index: 2;
        white-space: nowrap;
        font-size: 12px;
        color: #000;
        display: flex;
        align-items: center;
        cursor: pointer;
        .el-icon-close{
          font-size: 16px;
          font-weight: 600;
          display: inline-block;
          margin-left: 10px;
          color: #000;
        }
      }
      .game_container {
        padding: 0 2px 2px 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        .close{
          display: none;
        }
      }
      .game_bar{
        width: 100%;
        overflow: hidden;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        height: 64px;
        align-self: stretch;
        background: rgb(255, 255, 255);
        position: relative;
        z-index: 3;
        padding: 0px 16px 0px 0px;
        box-sizing: border-box;
        .bar_app_icon {
          font-size: 21px;
          display: flex;
          margin: 12px;
          .text_box{
            margin: auto 0px;
            h1{
              margin: 0px;
              font: 500 20px/24px Torus;
              padding: 0px 10px 0px 0px;
              color: #002b50;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            span{
              display: block;
              color: #5d6b84;
              font-size: 12px;
              line-height: 16px;
              margin: 4px 0px 0px;
              letter-spacing: 0.3px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
          img{
            width: 40px;
            height: 40px;
            border-radius: 8px;
            margin: 0px 8px 0px 0px;
            background: #bac9de;
          }
        }
        .logo2.logo2 {
          position: absolute;
          width: auto;
          height: 28px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          img{
            width: 100%;
            height: 100%;
          }
        }
        .logo1 {
          display: block;
          width: 100%;
          height: 100%;
          margin: 0px auto;
        }
        .bar_btns{
          margin-right: 10px;
          display: flex;
          position: relative;
          .btns_box {
            margin-left: auto;
            display: flex;
            align-items: center;
            .like_style {
              position: relative;
              width: 40px;
              height: 40px;
              margin: 8px 8px auto;
              border: none;
              outline: none;
              background: none;
              padding: 0px;
              cursor: pointer;
              .like_style_2 {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: var(--gameBarLabelBackgroundColor,#FFFFFF);
                transition: background-color 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                padding: 8px;
                box-sizing: border-box;
                .like_style_3 {
                  transform: rotate(var(--gameBarIconRotation,0deg)) translateY(var(--gameBarIconY,0px));
                  transition: fill 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s, transform 0.2s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                  overflow: visible;
                  text-align: center;
                  img{
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .dislike_style_2 {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: var(--gameBarLabelBackgroundColor,#FFFFFF);
                transition: background-color 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                padding: 8px;
                --gameBarButtonAnimationBackgroundColor: #ff99b1;
                box-sizing: border-box;
                .dislike_style_4 {
                  transform: rotate(var(--gameBarIconRotation,0deg)) translateY(var(--gameBarIconY,0px));
                  transition: fill 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s, transform 0.2s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                  overflow: visible;
                  text-align: center;
                  img{
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .button_box {
                position: absolute;
                left: 50%;
                bottom: 4px;
                font: 700 10px / 12px "Proxima Nova", sans-serif;
                .like_style_4 {
                  opacity: var(--gameBarLabelOpacity,1);
                  color: #5d6b84;
                  background-color: var(--gameBarLabelBackgroundColor,#FFFFFF00);
                }
                .button_box_3 {
                  opacity: var(--gameBarHoverLabelOpacity,0);
                  color: rgb(255, 255, 255);
                  background-color: #009cff;
                }
                .button_box_1 {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  transform: translate(-50%);
                  border-radius: 16px;
                  padding: 0px 4px;
                  white-space: nowrap;
                  transition: opacity 350ms cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                }
              }
            }
            .like_style:hover {
              --gameBarIconRotation: -5deg;
              --gameBarLabelOpacity: 0;
              --gameBarHoverLabelOpacity: 1;
              .like_style_1{
                background: var(--gameBarButtonBackgroundHoverColor,#f0f5fc);
              }
            }
            .reduce_box, .dislike_style {
              .reduce_1, .dislike_style_1 {
                line-height: 30px;
                .reduce_3, .dislike_style_3 {
                  text-align: center;
                  img{
                    width: 17px;
                    height: 17px;
                  }
                }
              }
              .dislike_style_1{
                --gameBarButtonAnimationBackgroundColor: #ff99b1;
              }
              .button_box {
                .like_style_4 {
                  background-color: var(--gameBarLabelBackgroundColor,#FFFFFF00);
                  --gameBarLabelBackgroundColor: #f0f5fc;
                }
                .button_box_3 {
                  opacity: var(--gameBarHoverLabelOpacity,0);
                  color: rgb(255, 255, 255);
                  background-color: #009cff;
                }
                .button_box_1 {
                  padding: 0px 4px 2px;
                }
              }
            }
          }
          .el-divider--vertical{
            height: 2em;
            margin: 8px;
          }
          .full_btn,.full_btn_download{
            position: relative;
            width: 40px;
            height: 40px;
            margin: 8px 8px auto;
            border: none;
            outline: none;
            background: none;
            padding: 0px;
            cursor: pointer;
            .like_style_2 {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background: var(--gameBarLabelBackgroundColor,#FFFFFF);
              transition: background-color 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
              padding: 8px;
              box-sizing: border-box;
              .like_style_3 {
                transform: rotate(var(--gameBarIconRotation,0deg)) translateY(var(--gameBarIconY,0px));
                transition: fill 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s, transform 0.2s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                overflow: visible;
                text-align: center;
                img{
                  width: 17px;
                  height: 17px;
                }
              }
            }
            .button_box {
              position: absolute;
              left: 50%;
              bottom: 4px;
              font: 700 10px / 12px "Proxima Nova", sans-serif;
              .like_style_4 {
                opacity: var(--gameBarLabelOpacity,1);
                color: #5d6b84;
                background-color: var(--gameBarLabelBackgroundColor,#FFFFFF00);
              }
              .button_box_3 {
                opacity: var(--gameBarHoverLabelOpacity,0);
                color: rgb(255, 255, 255);
                background-color: #009cff;
              }
              .button_box_1 {
                position: absolute;
                left: 0px;
                top: 0px;
                transform: translate(-50%);
                border-radius: 16px;
                padding: 0px 4px 2px;
                white-space: nowrap;
                transition: opacity 350ms cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
              }
            }
          }
          @keyframes jiggle {
            0%,90%,100%{ transform: rotate(0) scale(1);}
            20%,40%{ transform: rotate(-5deg) scale(1.1);}
            30%,50%{ transform: rotate(5deg) scale(1.1);}
          }
          .full_btn_download{
            width: 52px;
            height: 40px;
            animation: jiggle 1.8s linear 3;
            .like_style_2{
              padding-top: 6px!important;
            }
            .like_style_3{
              img{
                width: 37px!important;
                height: 22px!important;
              }
            }
          }
          .full_btn:hover {
            --gameBarIconRotation: -5deg;
            --gameBarLabelOpacity: 0;
            --gameBarHoverLabelOpacity: 1;
            .like_style_1{
              background: var(--gameBarButtonBackgroundHoverColor,#f0f5fc);
            }
          }
          .full_btn_download:hover {
            --gameBarIconRotation: -5deg;
            --gameBarLabelOpacity: 0;
            --gameBarHoverLabelOpacity: 1;
            .like_style_1{
              background: var(--gameBarButtonBackgroundHoverColor,#f0f5fc);
            }
          }
          .reduce_box, .dislike_style {
            position: relative;
            width: 40px;
            height: 40px;
            margin: 8px 8px auto;
            border: none;
            outline: none;
            background: none;
            padding: 0px;
            cursor: pointer;
            --gameBarIconRotation: -5deg;
            --gameBarIconY: -2px;
            @keyframes jtWJDL{
              0% {
                transform: scale(1);
              }
              40% {
                transform: scale(1.1);
                background-color: var(--gameBarButtonAnimationBackgroundColor,var(--green-3));
              }
              43% {
                transform: scale(1.2);
              }
              60% {
                transform: scale(0.9);
              }
              70% {
                transform: scale(1.05);
              }
              80% {
                transform: scale(1.01);
              }
              85% {
                transform: scale(1);
              }
              100% {
                transform: scale(1);
                background-color: var(--gameBarLabelBackgroundColor);
              }
            }
            .reduce_1, .dislike_style_1 {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background: var(--gameBarLabelBackgroundColor,#FFFFFF);
              transition: background-color 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
              padding: 8px;
              animation: 1s ease 0s 1 normal forwards running jtWJDL;
              //--gameBarLabelBackgroundColor: #009cff;
              --gameBarButtonBackgroundHoverColor: #0097f5;
              box-sizing: border-box;
              @keyframes comMCG{
                0% {
                  transform: rotate(0deg) translateY(var(--gameBarIconY,0px));
                }
                40% {
                  transform: rotate(20deg) translateY(var(--gameBarIconY,0px));
                }
                43% {
                  transform: rotate(25deg) translateY(var(--gameBarIconY,0px));
                }
                60% {
                  transform: rotate(-15deg) translateY(var(--gameBarIconY,0px));
                }
                70% {
                  transform: rotate(3deg) translateY(var(--gameBarIconY,0px));
                }
                80% {
                  transform: rotate(-1deg) translateY(var(--gameBarIconY,0px));
                }
                85% {
                  transform: rotate(-5deg) translateY(var(--gameBarIconY,0px));
                }
                100% {
                  transform: rotate(-5deg) translateY(var(--gameBarIconY,0px));
                }
              }
              .reduce_2 {
                animation: 1s ease 0s 1 normal none running comMCG;
              }
              .reduce_3, .dislike_style_3 {
                --gameBarIconFill: #FFFFFF;
                transform: rotate(var(--gameBarIconRotation,0deg)) translateY(var(--gameBarIconY,0px));
                transition: fill 0.6s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s, transform 0.2s cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
                overflow: visible;
                img{
                  width: 36px;
                  height: 36px;
                  margin-top: -5px;
                  margin-left: -6px;
                }
              }
            }
            .button_box {
              position: absolute;
              left: 50%;
              bottom: 4px;
              font: 700 10px / 12px "Proxima Nova", sans-serif;
              .button_box_2 {
                opacity: var(--gameBarLabelOpacity,1);
                color: #5d6b84;
                background-color: var(--gameBarLabelBackgroundColor,#FFFFFF00);
                --gameBarLabelBackgroundColor: #f0f5fc;
              }
              .button_box_3 {
                opacity: var(--gameBarHoverLabelOpacity,0);
                color: rgb(255, 255, 255);
                background-color: #009cff;
              }
              .button_box_1 {
                position: absolute;
                left: 0px;
                top: 0px;
                transform: translate(-50%);
                border-radius: 16px;
                padding: 0px 4px;
                white-space: nowrap;
                transition: opacity 350ms cubic-bezier(0.32, 1.2, 0.54, 1.17) 0s;
              }
            }
          }
          .reduce_box:hover {
            --gameBarLabelOpacity: 0;
            --gameBarIconRotation: 0deg;
            --gameBarHoverLabelOpacity: 1;
          }
          .dislike_style:hover {
            --gameBarLabelOpacity: 0;
            --gameBarIconRotation: 0deg;
            --gameBarHoverLabelOpacity: 1;
          }
        }
      }
    }
  }
  .a_style_2 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    user-select: none;
    aspect-ratio: 1 / 1;
  }
  .a_style_3{
    img{
      width: 94px;
      height: 94px;
    }
  }
  .a_style_1 {
    display: block;
    transition: transform .6s cubic-bezier(.25, .1, .25, 1);
    border-radius: 16px;
    position: relative;
    cursor: pointer;
  }
  .a_style_1::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 4;
    transition: box-shadow .6s var(--bezier),opacity .3s var(--bezier);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    contain: strict;
  }
  .a_style_1:hover {
    transform: scale(1.04255) translate(0px, -4px);
    transition-duration: 0.3s;
  }
  .a_style_4{
    border-radius: 0;
  }
  .img_style {
    display: block;
    --minSize: 94px;
    min-width: var(--minSize);
    min-height: var(--minSize);
    border-radius: inherit;
    aspect-ratio: 1 / 1;
  }
  .position {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    color: rgb(255, 255, 255);
    font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
    text-align: center;
    z-index: 6;
    transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
    transform: translate(0px, 8px);
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    word-break: break-all;
  }
  @media (hover: hover) {
    .a_style_2:hover {
      transform: scale(1.01869) translate(0px, -4px);
    }
    .a_style_2:hover::after {
      opacity: 1;
      background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
    }
    .a_style_2:hover .opacity {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
  @keyframes djqRmU{
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  @keyframes etkMLf{
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  .masking_1{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: djqRmU .2s cubic-bezier(.25, .1, .25, 1) both;
    z-index: 100001;
  }
  .masking{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 18px;
      height: 18px;
      margin: 0 2px;
      border-radius: 100%;
      background: white;
      animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
    }
    div:nth-child(2){
      animation-delay: -0.16s;
    }
  }
  .bottom-text{
    position: relative;
    margin: 24px 0px;
    padding: 18px 24px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    .gmEuRM ul {
      overflow: hidden;
      margin: 0px;
      padding: 0px;
      color: var(--grey-1);
      list-style: none;
      text-overflow: ellipsis;
      font: 400 16px/24px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
    }
    .gmEuRM li {
      display: inline;
      color: #5d6b84;
      a{
        text-decoration: none;
        color: #5d6b84;
      }
    }
    .gmEuRM li:nth-of-type(2)::before {
      content: "›";
      margin: 0px 4px;
      color: var(--grey-1);
      font-size: 13px;
    }
    .header_title {
      margin: 8px 0px 8px;
      h2{
        margin: 0px;
        font: 500 24px Torus, sans-serif;
        color: #002b50;
      }
    }
    .star_box{
      margin: 4px 0px;
      .star {
        display: inline-block;
        font-size: 12px;
        line-height: 22px;
        font-weight: bold;
        padding: 0px 8px;
        color: #5d6b84;
        background: #f0f5fc;
        border-radius: 10px;
        text-transform: uppercase;
        height: 20px;
        margin-right: 4px;
      }
    }
    .bottom_desc{
      color: #002b50;
      font: 400 16px/24px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
    }
    .type_style_2 {
      margin-top: 12px;
      margin-bottom: 8px;
    }
    .type_style {
      a{
        text-decoration: none;
        color: #0078FF;
      }
      li{
        display: inline-block;
        margin: 4px 4px 0px 0px;
        padding: 0px 10px;
        //border: 2px solid #bac9de;
        border-radius: 100px;
        color: #bac9de;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        background: #AEEBFE;
      }
      li:hover{
        background: #0078FF;
        a{
          color: #AEEBFE;
        }
      }
    }
  }
  .aside {
    position: fixed;
    bottom: 12px;
    right: 12px;
    z-index: 3;
    width: 100%;
    max-width: 352px;
    @keyframes ireoXe{
      0% {
        transform: translate(130%, 0px) scale(0.9);
      }
      60% {
        transform: translate(0px, 0px) scale(0.9);
      }
      75% {
        transform: translate(0px, 0px) scale(1.05);
      }
      85% {
        transform: translate(0px, 0px) scale(0.97);
      }
      95%, 100% {
        transform: translate(0px, 0px) scale(1);
      }
    }
    @keyframes iRsYLL{
      0% {
        transform: translate(0px, 0px) scale(1);
      }
      10% {
        transform: translate(0px, 0px) scale(1);
      }
      35% {
        transform: translate(0px, 0px) scale(0.9);
      }
      45% {
        transform: translate(130%, 0px) scale(0.9);
      }
      100% {
        transform: translate(130%, 0px) scale(0.9);
      }
    }
    .feedback_3, .feedback {
      max-width: 352px;
      width: 100%;
      color: #5d6b84;
      background: rgb(255, 255, 255);
      box-shadow: rgba(9, 30, 66, 0.07) 0px 16px 16px, rgba(9, 30, 66, 0.07) 0px 0px 8px, rgba(9, 30, 66, 0.07) 0px 16px 32px;
      border-radius: 12px;
      margin: 16px auto 0px;
      padding: 0px 0px 8px;
      transform: translate(110%, 0px);
      animation: 1000ms ease-in-out 0s 1 normal forwards running ireoXe;
      .feedback_header {
        padding: 0px 40px 0px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        .feedback_1 {
          font-family: Torus, sans-serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          padding: 14px 0px;
          color: #002b50;
          max-width: 90%;
          display: flex;
          align-items: center;
          position: relative;
          box-sizing: border-box;
          .feedback_2 {
            display: flex;
            align-items: center;
            padding: 4px 0px 0px;
            font: 700 12px / 1 "Proxima Nova", sans-serif;
            //text-transform: uppercase;
            color: #5d6b84;
            box-sizing: border-box;
            .btn_img_box {
              margin: 0px 13px 0px 0px;
              flex-shrink: 0;
              box-sizing: border-box;
              img{
                width: 17px;
                height: 17px;
              }
            }
            .desc{
              line-height: 18px;
            }
            strong {
              display: block;
              color: #002b50;
              font-size: 18px;
              text-transform: none;
            }
          }
        }
        .feed_back_close {
          width: 32px;
          height: 46px;
          padding: 3px 0px 0px;
          background: rgb(255, 255, 255);
          border: none;
          overflow: hidden;
          cursor: pointer;
          position: absolute;
          top: 0px;
          right: 8px;
          .feed_back_close_2 {
            position: absolute;
            top: 8px;
            left: 0px;
            width: 32px;
            height: 32px;
            background: #ffc9dd;
            border-radius: 16px;
            transform: translate(0px, -140%);
            transition: transform 0.25s cubic-bezier(0.19, 2.13, 0.73, 0.61) 0s;
          }
          .close {
            color: #009cff;
            position: relative;
            z-index: 2;
            font-size: 20px;
            /deep/.el-icon-close{
              font-weight: bold;
            }
          }
        }
        .feed_back_close:hover .feed_back_close_1, .feed_back_close:active .feed_back_close_1, .feed_back_close:focus .feed_back_close_1 {
          transform: translate(0px, 0px);
        }
      }
      .form_box {
        border-top: 2px solid #f0f5fc;
        padding: 8px 16px 16px;
        font-size: 14px;
        line-height: 20px;
        /deep/.el-form-item{
          margin-bottom: 0;
        }
        /deep/.el-textarea__inner{
          resize: none;
          height: 86px;
          color: #5d6b84;
          background: #f0f5fc;
          border: 2px solid #bac9de;
          font-size: 14px;
        }
        .guangjian {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 176px;
          height: 88px;
          border-radius: 8px;
          z-index: -1;
        }
        img {
          color: transparent;
        }
      }
      .send_box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 4px 16px;
        .sending {
          font: 500 16px/1.5 Torus, sans-serif;
          color: rgb(255, 255, 255);
          padding: 6px 24px;
          border-radius: 30px;
          background: #009cff;
          border: none;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out 0s;
        }
      }
    }
    .feedback {
      animation: 1000ms ease-in-out 0s 1 normal forwards running iRsYLL;
    }
    .messageBg {
      max-width: 352px;
      width: 100%;
      box-shadow: rgba(9,30,66,0.07) 0 16px 16px,rgba(9,30,66,0.07) 0 0 8px,rgba(9,30,66,0.07) 0 16px 32px;
      border-radius: 12px;
      margin: 16px auto 0;
      transform: translate(110%, 0px);
      animation: 1000ms ease-in-out 0s 1 normal forwards running ireoXe;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 144px;
      padding: 10px 28px;
      background-color: #009cff;
      font: 500 28px/1.1 Torus, sans-serif;
      color: white;
      box-sizing: border-box;
    }
  }
}
</style>
